import { Box, Table, TableRow } from "@mui/material";
import {
  MetadataSectionText,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledTableCell,
} from "./style";
import { ArrowDownIcon, ArrowOutwardIcon } from "../../../assets/Icons";
import { useTheme } from "styled-components";
import { Link } from "@mui/material";
import { DiscoveryItem } from "../../../store/discovery/types";
import { useState } from "react";
import { userDetailsSelector } from "../../../store/account/selector";
import { useSelector } from "react-redux";

const MetadataBox = ({
  item,
  isSwipeFilePage,
}: {
  item: DiscoveryItem;
  isSwipeFilePage: boolean;
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(true);

  const user = useSelector(userDetailsSelector);

  const date = item?.createdAt ? new Date(item?.createdAt) : new Date(); // Assuming you have a Date object

  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  const date1: Date = item?.startedRunningDate
    ? new Date(item?.startedRunningDate)
    : new Date();
  const date2: Date = new Date();

  const differenceMs = date2.getTime() - date1.getTime();

  const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  const hasVideos = item?.videoUrls?.length > 0;
  const hasImages = item?.imageUrls?.length > 0;

  const format =
    hasVideos && hasImages
      ? "Video, Image"
      : hasVideos
      ? "Video"
      : hasImages
      ? "Image"
      : "";

  const platformItems = item?.platforms
    ?.filter((platform) => platform)
    ?.join(", ");

  return (
    <Box>
      <StyledAccordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <StyledAccordionSummary
          expandIcon={<ArrowDownIcon />}
          sx={{ padding: "1.25rem" }}
        >
          Metadata
        </StyledAccordionSummary>
        <StyledAccordionDetails
          sx={{
            padding: "1.25rem",
            gap: "1.25rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Table>
            <TableRow>
              <StyledTableCell>
                <MetadataSectionText textColor={theme.colors.gray1}>
                  Brand
                </MetadataSectionText>
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  cursor: "pointer",
                }}
              >
                <MetadataSectionText textColor={theme.colors.link}>
                  <Link
                    style={{ textDecoration: "none" }}
                    target={"_blank"}
                    href={item?.page?.url ?? "#"}
                  >
                    {item?.page?.name}
                  </Link>
                  <ArrowOutwardIcon fill={theme.colors.link} />
                </MetadataSectionText>
              </StyledTableCell>
            </TableRow>

            {isSwipeFilePage && (
              <TableRow>
                <StyledTableCell>
                  <MetadataSectionText textColor={theme.colors.gray1}>
                    Saved by
                  </MetadataSectionText>
                </StyledTableCell>
                <StyledTableCell>
                  <MetadataSectionText textColor={theme.colors.black2}>
                    {user?.fullName.charAt(0).toUpperCase() +
                      user?.fullName.slice(1)}
                    , {formattedDate}
                  </MetadataSectionText>
                </StyledTableCell>
              </TableRow>
            )}

            <TableRow>
              <StyledTableCell>
                <MetadataSectionText textColor={theme.colors.gray1}>
                  Active
                </MetadataSectionText>
              </StyledTableCell>
              <StyledTableCell>
                <MetadataSectionText textColor={theme.colors.black2}>
                  {item?.startedRunningDate
                    ? item?.startedRunningDate.concat(
                        " - ",
                        item?.isActive ? "Still Running" : "Not Running"
                      )
                    : "N/A"}
                </MetadataSectionText>
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell sx={{ minWidth: "7.5rem" }}>
                <MetadataSectionText textColor={theme.colors.gray1}>
                  Time Running
                </MetadataSectionText>
              </StyledTableCell>
              <StyledTableCell>
                <MetadataSectionText textColor={theme.colors.black2}>
                  {Number.isNaN(differenceDays)
                    ? "N/A"
                    : differenceDays.toString() + " Days"}
                </MetadataSectionText>
              </StyledTableCell>
            </TableRow>

            {format && (
              <TableRow>
                <StyledTableCell>
                  <MetadataSectionText textColor={theme.colors.gray1}>
                    Format
                  </MetadataSectionText>
                </StyledTableCell>
                <StyledTableCell>
                  <MetadataSectionText textColor={theme.colors.black2}>
                    {format}
                  </MetadataSectionText>
                </StyledTableCell>
              </TableRow>
            )}

            {platformItems?.length > 0 && (
              <TableRow>
                <StyledTableCell>
                  <MetadataSectionText textColor={theme.colors.gray1}>
                    Platforms
                  </MetadataSectionText>
                </StyledTableCell>
                <StyledTableCell>
                  <MetadataSectionText
                    textColor={theme.colors.black2}
                    style={{ textTransform: "capitalize" }}
                  >
                    {platformItems}
                  </MetadataSectionText>
                </StyledTableCell>
              </TableRow>
            )}

            {item?.adTargetSourceURL ? <TableRow>
              <StyledTableCell>
                <MetadataSectionText textColor={theme.colors.gray1}>
                  Landing Page
                </MetadataSectionText>
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  cursor: "pointer",
                }}
              >
                <MetadataSectionText textColor={theme.colors.link}>
                  <Link
                    sx={{ textDecoration: "none" }}
                    target={"_blank"}
                    href={item?.adTargetSourceURL}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    width="14rem"
                    display="inline-block"
                  >
                    {item?.adTargetSourceURL}
                  </Link>
                  <Link target={"_blank"} href={item?.adTargetSourceURL}>
                    <ArrowOutwardIcon fill={theme.colors.link} />
                  </Link>
                </MetadataSectionText>
              </StyledTableCell>
            </TableRow> : ''}
          </Table>
        </StyledAccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default MetadataBox;
