import styled from "styled-components";
import { Button, Grid, Box } from "@mui/material";
import { Link, NavLink } from "react-router-dom";

export const LoginLayoutContainer = styled(Grid)`
  padding: 4% 0 0 6%;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  overflow: hidden;
  height: 100vh;
  @media (max-width: 899px) {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 13%;
  }
`;

export const LogoWrapper = styled.img`
  width: 21rem;
  margin-bottom: 2%;

  &:hover{
    cursor: pointer;
  }
  @media (max-width: 1440px) {
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const PageTitle = styled.h2`
  font-weight: 700;
  font-size: 2.2rem;
  margin: 3% 0 2% 0;
`;

export const AccountText = styled.span`
  font-weight: 400;
  font-size: 1.275rem;
  @media (max-width: 1440px) {
    font-size: 1.125rem;
  }
`;

export const AccountLoginText = styled(Link)`
  font-weight: 500;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const FormWrapper = styled.form`
  margin-top: 7%;
  @media (max-width: 1440px) {
    margin-top: 25px;
  }
`;

export const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  @media (max-width: 1440px) {
    gap: 15px;
  }
`;

export const TermsAndPolicyText = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 20.94px;
  display: flex;
  align-items: center;
  @media (max-width: 1440px) {
    font-size: 0.875rem;
  }
`;

export const BottomButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.7rem;
  @media (max-width: 1440px) {
    margin-top: 25px;
  }
`;

export const StyledLoginButton = styled(Button)<{ withGoogle?: boolean }>`
  height: 3rem;
  width: 100% !important;
  && {
    border-radius: 36px;
    font-size: 1rem;
    font-weight: 600;
    /* line-height: 20.94px; */
    text-transform: capitalize;
    background: ${({ withGoogle, theme }) =>
      withGoogle
        ? theme.colors.white
        : "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"} !important;
    color: ${({ withGoogle, theme }) =>
      withGoogle ? theme.colors.neutral100 : theme.colors.white};
  }

  &:hover,
  &:focus {
    && {
      background: ${({ withGoogle, theme }) =>
        withGoogle
          ? theme.colors.white
          : "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"};
      color: ${({ withGoogle, theme }) =>
        withGoogle ? theme.colors.neutral100 : theme.colors.white};
    }
  }
`;

export const GoogleIconWrapper = styled.span`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

export const ForgotPasswordText = styled.div`
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: end;
  @media (max-width: 1440px) {
    font-size: 0.875rem;
  }
`;

export const CredentialSection = styled(Grid)`
  padding-right: 10%;
  @media (max-width: 900px) {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 1rem;
  font-weight: bolder;
`;

export const ImageSection = styled(Grid)`
  transform: rotate(-0.05turn) translate(calc(100% + 29rem), -5%);
  display: flex;
  @media  (min-width: 1451px) and (max-width: 1700px) {
    transform: rotate(-0.05turn) translate(calc(100% + 40rem), -5%);
  }
  @media (min-width: 1201px) and (max-width: 1450px) {
    transform: rotate(-0.05turn) translate(calc(100% + 49rem), -5%);
  }
  @media (min-width: 1100px) and (max-width: 1200px) {
    transform: rotate(-0.05turn) translate(calc(100% + 66rem), -5%);
  }
  @media (min-width: 901px) and (max-width: 1099px) {
    transform: rotate(-0.05turn) translate(calc(100% + 72rem), -5%);
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const CarouselContainer = styled.div`
  z-index: -1;
`;

export const PanelItem = styled(NavLink)<{ drawerOpen?: boolean, to?: string }>`
  color: white !important;
  display: flex !important;
  text-decoration: none !important;
  justify-content: ${({ drawerOpen }) =>
    drawerOpen ? "start" : "center"} !important;
  border-radius: 0.313rem !important;
  text-transform: capitalize !important;
  width: ${({ drawerOpen }) =>
          drawerOpen ? "12.625rem" : "3rem"} !important;
  margin: ${({ drawerOpen }) =>
          drawerOpen ? "0.6rem 1.9rem" : "0.6rem 2rem"} !important;
  margin-left: ${({ to }) =>
          to==="/starred" ? "0.8rem" : "1.43rem 2.43rem"} !important;
  height: 3rem;
  align-items: center;
  padding-left:${({ drawerOpen }) =>
          drawerOpen ? " 0.614rem" : "0"};
  @media only screen and (max-width: 1440px){
    width: ${({ drawerOpen }) =>
            drawerOpen ? "12rem" : "2.4rem"} !important;
    margin: ${({ drawerOpen }) =>
            drawerOpen ? "0.6rem 1.8rem" : "0.6rem 1.3rem"} !important;
    margin-left: ${({ to }) =>
            to==="/starred" ? "0.8rem" : "1.43rem 2.43rem"} !important;
    height: 2.4rem;
    svg{
      width: 1.625rem;
      height: 1.625rem;
    }
  }
`;

export const PanelLogo = styled.img`
  display: flex !important;
  justify-content: start !important;
  width: 12rem;
  height: 1.625rem;
  margin: 1.785rem 2.428rem 1.8rem 1.825rem !important;
  @media only screen and (max-width: 1440px){
    width: 12rem;
    height: 1.657rem;
    margin-bottom: 2rem!important;
  }
`;
export const PanelLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 2.21rem;
  height: 2.857rem;
  margin: 1.4rem 3rem 2.6rem 2.6rem;
  @media only screen and (max-width: 1440px){
    width: 2rem;
    height: 2.65rem;
    margin: 1.4rem 4rem 1.29rem 1.6rem;
  }
`;

export const BlurredOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px); /* Adjust blur strength */
  background: #ffffff26; /* Semi-transparent overlay */
  z-index: 10; /* Ensure it's above other elements */
  user-select: none;
`;

export const FolderListItem = styled.span`
  color: white;
  display: flex;
  text-decoration: none;
  justify-content: start;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 0.313rem;
  text-transform: capitalize;
`;
export const SidebarWrapper = styled.div<{ drawerOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ drawerOpen }) =>
          drawerOpen ? "16.25rem" : "7.25rem"} !important;
  color: white;
  position:fixed;
  @media only screen and (max-width: 1440px){
    width: ${({ drawerOpen }) =>
            drawerOpen ? "16rem" : "5rem"} !important;
  }
`;
export const MainLayoutWrapper = styled(Box)<{ drawerOpen?: boolean }>`
  background:${({ theme }) => theme.colors.background4};
  overflow-x: hidden;
  width: ${({ drawerOpen }) =>
          drawerOpen ? "calc(100vw - 16.25rem)" : "calc(100vw - 7.25rem)"};
  border-radius: 1.25rem 0 0 1.25rem;
  margin-left: ${({ drawerOpen }) =>
          drawerOpen ? "16.25rem" : "7.25rem"};
  min-height: 100vh;
  @media only screen and (max-width: 1440px){
    width: ${({ drawerOpen }) =>
    drawerOpen ? "calc(100vw - 16rem)" : "calc(100vw - 5rem)"} !important;
    margin-left: ${({ drawerOpen }) =>
            drawerOpen ? "16rem" : "5rem"};
  }
`;

export const LoginTextBox = styled.div`
  margin: 1.25rem 0 2.187rem 0;
  padding: 0.812rem 0.75rem;
  font-weight: 400;
  font-size: 1rem;
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const TermsLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.lightBlue};
  text-decoration: none;
  margin: 0 0.3rem;
`