import {KeyboardEventHandler, ReactNode, useEffect, useMemo} from "react";
import {
  BrandCountText,
  BrandGridContainer,
  Container,
  HeaderSection,
  HeaderSubTitle,
  HeaderTitle,
  ListContainer,
} from "../style";
import { discoveryTabs } from "../../utils/constants";
import Header from "../../components/header";
import SearchAndFilter from "../../components/searchAndFilter";
import TabsHeader from "../../components/tabsHeader";
import AdCard from "../../components/commonComponents/adCard";
import CreateBoardModal from "../../components/modals/createBoardModal";
import { useDispatch, useSelector } from "react-redux";
import {
  communityItemsSelector,
  discoveryBrandsParamsSelector,
  discoveryBrandsSelector,
  discoveryFavouriteBrandFilterSelector,
  discoveryFilterCountsSelector,
  discoveryItemsSelector,
  discoveryPageSearchSelector,
  discoverySelectedBrandsSelector,
  discoverySelectedPlatformsSelector,
  discoverySelectedSortOptionSelector,
  discoveryTabIndexSelector,
  isLoadingSelector,
} from "../../store/discovery/selector";
import { DiscoverySagaActions } from "../../store/discovery/sagas";
import { DiscoveryItem } from "../../store/discovery/types";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import SaveAdPopover from "../../components/saveAdPopover";
import { Box, CircularProgress, Grid } from "@mui/material";
import BrandCard from "../../components/commonComponents/brandCards/brandCard";
import { DiscoveryReducerAction } from "../../store/discovery/slice";
import { BoardItem, brandItem, listType } from "../../store/metadata/types";
import CardListWrapper from "../../components/commonComponents/adCard/cardListWrapper";
import TopBrandCarousel from "../../components/commonComponents/brandCards/topBrandCarousel";
import InfiniteScroll from "react-infinite-scroll-component";
import FavouriteBrandDiscovery from "../../components/favouriteBrand/favouriteBrandDiscovery";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { useLocation } from "react-router-dom";

const Discovery = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const discoveryItems = useSelector(discoveryItemsSelector);

  const communityItems = useSelector(communityItemsSelector);

  const discoveryBrandLists = useSelector(discoveryBrandsSelector);

  const isLoading = useSelector(isLoadingSelector);

  const discoveryTabIndex = useSelector(discoveryTabIndexSelector);

  const selectedBrands = useSelector(discoverySelectedBrandsSelector);

  const selectedPlatforms = useSelector(discoverySelectedPlatformsSelector);

  const searchValue = useSelector(discoveryPageSearchSelector);

  const discoverySelectedSortOption = useSelector(
    discoverySelectedSortOptionSelector
  );
  const favouriteBrandFilterEnabled = useSelector(
    discoveryFavouriteBrandFilterSelector
  );
  const discoveryBrandsParams = useSelector(discoveryBrandsParamsSelector);
  const discoveryFilterCounts = useSelector(discoveryFilterCountsSelector);

  const content = {
    tabTitle: "Explore Lounge",
    tabSubTitle: `Browse ${discoveryItems?.total !== undefined ? discoveryItems?.total : "0"}+ ads saved by users`,
  };

  const setDiscoveryTabIndex = (tabIndex: number) => {
        dispatch(DiscoveryReducerAction.setDiscoveryTabIndex(tabIndex));
        if (tabIndex === 2) {
            if (searchValue) {
                dispatch(
                    DiscoverySagaActions.fetchSearchTopBrandList({
                        handleScroll: handleScrollForSearchBrand,
                    })
                );
            } else {
                dispatch(
                    DiscoverySagaActions.fetchDiscoveryTopBrandList(discoveryBrandsParams)
                );
            }

        } else if (tabIndex === 1) {
            dispatch(DiscoveryReducerAction.resetDiscoveryState());

            if (searchValue) {
                dispatch(DiscoverySagaActions.fetchSearchedCommunityItem());
            } else {
                dispatch(DiscoverySagaActions.fetchCommunityList());
            }

        } else {
            dispatch(DiscoveryReducerAction.resetDiscoveryState());
            if (searchValue) {
                dispatch(DiscoverySagaActions.fetchSearchedDiscoveryItem());
            } else {
                dispatch(DiscoverySagaActions.fetchDiscoveryList());
            }


        }
    };

  const refetchDiscoveryItem = (adId: string, updatedBoardList: BoardItem) => {
    dispatch(
      DiscoveryReducerAction.setUpdatedBoardIds({ adId, updatedBoardList })
    );
  };

  const fetchfilterDiscoveryItems = (
    selectAll: boolean,
    checked: boolean,
    stateName: string,
    list: listType[],
    id?: string
  ) => {
    dispatch(
      DiscoverySagaActions.applyFilterOnDiscoveryList({
        selectAll,
        checked,
        id,
        list,
        stateName,
      })
    );
  };

  const fetchSortingDiscoveryItems = (
    sortValue: string,
    sortObject: Object
  ) => {
    dispatch(
      DiscoveryReducerAction.setSelectedSortOption({
        sortValue,
        sortObject,
      })
    );
    if (discoveryTabs[discoveryTabIndex].id === "discovery") {
      dispatch(DiscoverySagaActions.fetchSortedDiscoveryList());
    } else if (discoveryTabs[discoveryTabIndex].id === "community") {
      dispatch(DiscoveryReducerAction.setFilterOptionSkipValue(0));
      dispatch(DiscoverySagaActions.fetchCommunityList());
    }
  };

  const handleSearchText: KeyboardEventHandler<HTMLInputElement> = (event) => {
    hanldeSearchButton();
  };

  const handleSearchChange = (value: string) => {
    dispatch(DiscoveryReducerAction.setDiscoveryPageSearchInputValue(value));
  };

  const handleScrollForSearchBrand = () => {
    window.scrollTo({
      top: 700,
      behavior: "smooth",
    });
  };

  const hanldeSearchButton = () => {
    if (discoveryTabs[discoveryTabIndex].id === "discovery") {
      dispatch(DiscoverySagaActions.fetchSearchedDiscoveryItem());
    } else if (discoveryTabs[discoveryTabIndex].id === "community") {
      dispatch(DiscoverySagaActions.fetchSearchedCommunityItem());
    } else if (discoveryTabs[discoveryTabIndex].id === "brands") {
      dispatch(
        DiscoverySagaActions.fetchSearchTopBrandList({
          handleScroll: handleScrollForSearchBrand,
        })
      );
    }
  };
  const handleViewAdsButtonClicked = (
    brandId: string,
    handleLoadingState: Function
  ) => {
    dispatch(
      DiscoverySagaActions.fetchBrandDiscoveryItems({
        brandId,
        handleLoadingState,
      })
    );
  };

  const fetchMoreBrands = () => {
    dispatch(
      DiscoverySagaActions.fetchDiscoveryTopBrandList({
        ...discoveryBrandsParams,
        skip: discoveryBrandsParams.skip + discoveryBrandsParams.limit,
      })
    );
  };

  const handleDiscoveryBrandFavouriteFilter = () => {
    dispatch(
      DiscoveryReducerAction.setDiscoveryFavouriteBrandFilter(
        !favouriteBrandFilterEnabled
      )
    );
    dispatch(
      DiscoverySagaActions.fetchDiscoveryTopBrandList({
        ...discoveryBrandsParams,
        skip: 0,
        favourite: !favouriteBrandFilterEnabled,
      })
    );
  };

  const fetchMoreDiscoveryItem = () => {
    dispatch(DiscoverySagaActions.fetchDiscoveryList());
  };

  const fetchMoreCommunityItem = () => {
    if (location.hash.split("?")[1] === undefined) {
      dispatch(DiscoverySagaActions.fetchCommunityList());
    }
  };

  const handleClearDiscoveryFilter = () => {
    dispatch(DiscoveryReducerAction.resetDiscoveryState());
    dispatch(DiscoverySagaActions.fetchDiscoveryList());
  };

  const handleViewBrandAds = (brandId: string) => {
    dispatch(
      DiscoverySagaActions.fetchSelectedBrandDiscoveryItems({
        brandId,
      })
    );
  };

  const handleArchive = (pageId: string,isArchived:boolean) => {
    dispatch(
        DiscoverySagaActions.fetchArchiveAds({
          pageId,
          isArchived: !isArchived,
        })
    );
  };

  useEffect(() => {
    dispatch(MetaDataSagaActions.fetchFavouriteBrands());

    if (location.hash.split("?")[1] === undefined) {
      dispatch(DiscoverySagaActions.fetchDiscoveryList());
    }
    dispatch(MetaDataSagaActions.fetchBoardList());
    dispatch(MetaDataSagaActions.fetchBrandLists());

    return () => {
      dispatch(DiscoveryReducerAction.resetDiscoveryState());
      dispatch(MetaDataReducerAction.setBrandFilterSearchText(""));
    };
  }, [dispatch]);

  useEffect(() => {
    if (discoveryTabIndex === 1) fetchMoreCommunityItem();
  }, []);

  const RenderAdsComponent = useMemo(() => {
    const hasMoreData = discoveryItems?.total > discoveryItems?.data?.length;
    const discoveryListItemsLength = discoveryItems?.data?.length ?? 0;

    if (!isLoading && discoveryListItemsLength === 0) {
      return (
        <ListContainer>
          <HeaderTitle textAlign="center">
            Seems like we don't have Ads for the Filter Applied 🥺
          </HeaderTitle>
          <HeaderSubTitle textAlign="center">
            Use our extension to save ads and contribute 😊. Else please remove the filer and check again . Thanks
          </HeaderSubTitle>
        </ListContainer>
      );
    }

    return (
      <>
        <FavouriteBrandDiscovery />
        <ListContainer>
          <CardListWrapper>
            {discoveryItems?.data?.map((item: DiscoveryItem, index: number) => (
              <AdCard
                item={item}
                key={index}
                index={index}
                dataList={discoveryItems?.data}
                handleViewAd={handleViewBrandAds}
                handleArchived = {handleArchive}
                locationPathname={location.pathname}
                ArchiveShow={true}
              />
            ))}
            {(isLoading || hasMoreData) &&
              [...Array(4)]?.map((index) => (
                <AdCard item={{} as DiscoveryItem} key={index} />
              ))}
          </CardListWrapper>
          <InfiniteScroll
              scrollableTarget={document.getElementById('content') as ReactNode}
            dataLength={discoveryListItemsLength ?? 0}
            next={fetchMoreDiscoveryItem}
            hasMore={hasMoreData}
            loader={
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            }
            style={{ overflow: "hidden" }}
            key={discoveryListItemsLength}
          >
            <></>
          </InfiniteScroll>
        </ListContainer>
      </>
    );
  }, [isLoading, discoveryItems, location.pathname]);

  const RenderCommunityComponent = useMemo(() => {
    const hasMoreData = communityItems?.total > communityItems?.data?.length;

    if (!isLoading && communityItems?.data?.length === 0) {
      return (
        <ListContainer>
          <HeaderTitle textAlign="center">
            Seems like we don't have Ads for the Filter Applied 🥺
          </HeaderTitle>
          <HeaderSubTitle textAlign="center">
            Use our extension to save ads and contribute 😊. Else please remove the filer and check again . Thanks
          </HeaderSubTitle>
        </ListContainer>
      );
    }
    return (
      <>
        <ListContainer>
          <CardListWrapper>
            {communityItems?.data?.map((item: DiscoveryItem, index: number) => (
              <AdCard
                item={item}
                key={item._id}
                index={index}
                dataList={communityItems?.data}
                handleViewAd={handleViewBrandAds}
                locationPathname={location.pathname}
                handleArchived = {handleArchive}
                ArchiveShow={true}
              />
            ))}
            {(isLoading || hasMoreData) &&
              [...Array(4)]?.map((index) => (
                <AdCard item={{} as DiscoveryItem} key={index} />
              ))}
          </CardListWrapper>
          <InfiniteScroll
              scrollableTarget={document.getElementById('content') as ReactNode}
            dataLength={communityItems?.data?.length ?? 0}
            next={fetchMoreCommunityItem}
            hasMore={hasMoreData}
            loader={
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            }
            style={{ overflow: "hidden" }}
            key={communityItems?.data?.length}
          >
            <></>
          </InfiniteScroll>
        </ListContainer>
      </>
    );
  }, [communityItems?.data, communityItems?.total, isLoading, location.pathname]);

  const RenderBrandsComponent = useMemo(() => {
    const discoveryBrandListsLength = discoveryBrandLists?.data?.length ?? 0;

    if (isLoading) {
      return (
        <ListContainer sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </ListContainer>
      );
    }

    if (favouriteBrandFilterEnabled && discoveryBrandListsLength === 0) {
      return (
        <ListContainer>
          <HeaderTitle textAlign="center">No favorite brands.</HeaderTitle>
        </ListContainer>
      );
    }
    return (
      <ListContainer>
        {!favouriteBrandFilterEnabled && (
          <TopBrandCarousel
            brandsTitle="Brands with Most Saved Ads"
            topBrandLists={discoveryBrandLists?.top10}
            handleViewAd={handleViewAdsButtonClicked}
          />
        )}
        {discoveryBrandListsLength > 1 && (
          <BrandCountText> Other Popular Brands on the Platform</BrandCountText>
        )}
        <InfiniteScroll
            scrollableTarget={document.getElementById('content') as ReactNode}
          dataLength={discoveryBrandListsLength ?? 0}
          next={fetchMoreBrands}
          hasMore={discoveryBrandLists?.total > discoveryBrandListsLength}
          loader={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          }
          style={{ overflow: "hidden" }}
        >
          <BrandGridContainer container spacing={1.25}>
            {discoveryBrandLists?.data?.map(
              (item: brandItem, index: number) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <BrandCard
                    item={item}
                    handleViewAd={handleViewAdsButtonClicked}
                  />
                </Grid>
              )
            )}
          </BrandGridContainer>
        </InfiniteScroll>
      </ListContainer>
    );
  }, [discoveryBrandLists?.data, discoveryBrandLists?.top10, isLoading, favouriteBrandFilterEnabled, discoveryBrandLists?.total]);

  const discoverySections: {
    [key: number]: JSX.Element;
  } = {
    0: <>{RenderAdsComponent}</>,
    1: <>{RenderCommunityComponent}</>,
    2: <>{RenderBrandsComponent}</>,
  };

  return (
    <Container>
      <HeaderSection>
        <Header content={content} />
        <SearchAndFilter
          handleFilter={fetchfilterDiscoveryItems}
          selectedBrands={selectedBrands}
          selectedPlatforms={selectedPlatforms}
          handleSortFilter={fetchSortingDiscoveryItems}
          selectedFilterOption={discoverySelectedSortOption}
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
          hanldeSearchButton={hanldeSearchButton}
          buttonsToShow={
            discoveryTabs[discoveryTabIndex].searchAndFilterButtons
          }
          favouriteBrandFilter={{
            value: favouriteBrandFilterEnabled,
            setValue: handleDiscoveryBrandFavouriteFilter,
          }}
          filterCounts={discoveryFilterCounts}
          handleClearFilter={handleClearDiscoveryFilter}
          handleKeyDown={handleSearchText}
        />
        <TabsHeader
          tabIndex={discoveryTabIndex}
          setTabIndex={setDiscoveryTabIndex}
          data={discoveryTabs}
        />

        {discoverySections[discoveryTabIndex]}
      </HeaderSection>
      <SaveAdPopover reFetchData={refetchDiscoveryItem} />
      <CreateBoardModal />
    </Container>
  );
};

export default Discovery;
