import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MetaDataState, SetFilterOptionsPayload, listType } from "./types";
import { boardType } from "../discovery/types";
import { FilterOptions } from "../swipeFile/types";

export const initialState: MetaDataState = {
  isLoading: false,
  teamListIsLoading:false,
  loadingBoard: false,
  openCreateFolder: false,
  folderLists: null,
  boardItems: [],
  boardIds: [],
  openBoardModal: false,
  selectedFolder: null,
  selectedFolderDetails: null,
  selectedSharedFolder: null,
  selectMultipleBoards: [],
  tagsLists: [],
  brandsList: [],
  saveAdPopupAnchorEl: null,
  selectedSaveAdId: "",
  adDetailsData: null,
  favouriteBrands: [],
  favouriteBrandIds: [],
  adNavigationIndex: 0,
  brandFilterSearchText: "",
  boardFilterSearchText: "",
  currenplayingVideoState: {
    currentPlayingVideo: null,
    setPlayButtonForCurrentVideo: () => {},
  },
  openSaveTranscriptModal: false,
  saveTranscriptLoader: false,
  favouriteFolderList: [],
  openSaveNewAd: false,
  openSaveNewAdLoader: false,
  modalTabIndex: 0,
  downloadMediaLoader: false,
  folderItem: null,
  openBoardDropdown: "",
  tagFilterSearchText: "",
  deleteFolderLoading: false,
  openViewFolderModal: false,
  openCreateTeamModal: false,
  createTeamLoading: false,
  teamDetails: [],
  invitationLoader: false,
  removeMemberLoader: false,
  notificationList: null,
  checkNotificationStatus: false,
  acceptJoinTeamLoader: false,
  rejectJoinTeamLoader: false,
  teamMemberEmail: "",
  notificationLoader: false,
  selectedFolderId: null,
  selectedSharedFolderId: null,
  boardAdsItems: {
    data: [],
    total: 0,
  },
  sharedBoardAdsItems: {
    data: [],
    total: 0,
  },
  folderAccessMemberList: [],
  folderTeamMemberList: {
    data: [],
    total: 0,
  },
  isRemoveUserModalOpen: {
    isOpen: false,
    isUser: false,
  },
  removeTeamMember: {
    userId: "",
  },
  removeTeamLoader: false,
  sharedFolderList: [],
  filterOptions: {
    selectedPlatformIds: [],
    selectedPageIds: [],
    selectedBoardIds: [],
    userId: "",
    limit: 30,
    skip: 0,
    sortValue: "Newest",
    sort: {
      createdAt: -1,
    },
    selectedTagIds: [],
  },
  usersModalOptions: {
    userLimit: 10,
    skip: 0,
  },
  search: "",
  selectedTabIndex: 0,
  openRegisterNowModal: false,
  selectedTeamFolder:[],
};

const metaData = createSlice({
  name: "metaDataState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTeamIsLoading(state, action) {
      state.teamListIsLoading = action.payload;
    },
    setBoardLoading(state, action) {
      state.loadingBoard = action.payload;
    },
    setOpenCreateFolder(state, action) {
      state.openCreateFolder = action.payload;
    },
    setFolderItems(state, action) {
      state.folderLists = action.payload;
    },
    setBoardItems(state, action) {
      state.boardItems = action.payload;
    },
    setBoardId(state, action) {
      const boardId = action.payload;
      const index = state.boardIds.indexOf(boardId);
      if (index === -1) {
        state.boardIds.push(boardId);
      } else {
        state.boardIds.splice(index, 1);
      }
    },
    setBoardItem(state, action) {
      state.boardItems.push(action.payload);
    },
    setOpenBoardModal(state, action) {
      state.openBoardModal = action.payload;
    },
    setBoardIds(state, action) {
      const { selectAll, id, checked } = action.payload;
      if (selectAll && checked) {
        state.boardIds = state.boardItems.map((item) => item._id);
      } else if (selectAll && !checked) {
        state.boardIds = [];
      } else {
        const index = state.boardIds.indexOf(id);
        if (index === -1) {
          state.boardIds.push(id);
        } else {
          state.boardIds.splice(index, 1);
        }
      }
    },
    setSelectedFolder(state, action) {
      state.selectedFolder = action.payload;
    },
    updateSelectedFolderBoard(state, action) {
      if(state?.selectedFolder?.boards?.length){
        state.selectedFolder.boards = state.selectedFolder.boards.map((item) => {
          if (item._id === action.payload._id) {
            return {
              ...item,
              ...action.payload
            };
          }
          return item;
        });
      }
    },
    setSelectedTeam(state, action) {
      state.selectedTeamFolder = action.payload;
    },
    setSelectedFolderDetails(state, action) {
      state.selectedFolderDetails = action.payload;
    },
    setSelectedFolderId(state, action) {
      state.selectedFolderId = action.payload;
    },
    setSelectedSharedFolderId(state, action) {
      state.selectedSharedFolderId = action.payload;
    },
    setNewFolderItem(state, action) {
      state.folderLists?.userFolders.push(action.payload);
      state.folderLists?.teamFolders.push(action.payload);
    },
    setSelectedMultipleBoard(state, action) {
      const selectedValue = action.payload;
      state.selectMultipleBoards =
        typeof selectedValue === "string"
          ? selectedValue.split(",")
          : selectedValue;
    },
    setTagsList(state, action) {
      state.tagsLists = action.payload;
    },
    setBrandsList(state, action) {
      state.brandsList = action.payload;
    },
    setSaveAdPopupAnchorEl(state, action) {
      state.saveAdPopupAnchorEl = action.payload;
    },
    setSelectedSaveAdId(state, action) {
      state.selectedSaveAdId = action.payload;
    },
    setAdSelectedBoardIds(state, action) {
      const boardIds = action.payload?.map((item: boardType) => item._id);
      return {
        ...state,
        boardIds: state.boardIds ? state.boardIds.concat(boardIds) : boardIds,
      };
    },
    setBoardIdstoInitialSet(state, action) {
      state.boardIds = action.payload;
    },
    setAdDetails(state, action) {
      state.adDetailsData = action.payload;
    },
    setFavouriteBrands(state, action) {
      state.favouriteBrands = action.payload;
    },
    setFavouriteBrandsIds(state, action) {
      state.favouriteBrandIds = action.payload;
    },
    setAdNavigationIndex(state, action) {
      state.adNavigationIndex = action.payload;
    },
    setBrandFilterSearchText(state, action) {
      state.brandFilterSearchText = action.payload;
    },
    setBoardFilterSearchText(state, action) {
      state.boardFilterSearchText = action.payload;
    },
    setNewTagToTagList(state, action) {
      state.tagsLists.push(action.payload);
    },
    setCurrentPlayingVideo(state, action) {
      state.currenplayingVideoState = {
        currentPlayingVideo: action.payload.video,
        setPlayButtonForCurrentVideo: action.payload.setStartVideo,
      };
    },
    setOpenSaveTranscriptModal(state, action) {
      state.openSaveTranscriptModal = action.payload;
    },
    setSaveTranscriptLoader(state, action) {
      state.saveTranscriptLoader = action.payload;
    },
    setFavouriteFolderList(state, action) {
      state.favouriteFolderList = action.payload;
    },
    setOpenSaveNewAd(state, action) {
      state.openSaveNewAd = action.payload;
    },
    setOpenSaveAdLoader(state, action) {
      state.openSaveNewAdLoader = action.payload;
    },
    setModalTabIndex(state, action) {
      state.modalTabIndex = action.payload;
    },
    setDownloadMediaLoader(state, action) {
      state.downloadMediaLoader = action.payload;
    },
    setFolderItem(state, action) {
      state.folderItem = action.payload;
    },
    setOpenBoardDropdown(state, action) {
      state.openBoardDropdown = action.payload;
    },
    setBoardIdsToInitialSet(state) {
      state.boardIds = [];
    },
    setTagFilterSearchText(state, action) {
      state.tagFilterSearchText = action.payload;
    },
    setDeleteFolderLoading(state, action) {
      state.deleteFolderLoading = action.payload;
    },
    setOpenViewFolderModal(state, action) {
      state.openViewFolderModal = action.payload;
    },
    setUpdatedFolderBoardItems(state, action) {
      state.folderItem = action.payload.updatedFolderItem;

      // Updating Main folderLists
      state.folderLists = action.payload.updatedFolderLists;
    },
    setOpenCreateTeamModal(state, action) {
      state.openCreateTeamModal = action.payload;
    },
    setCreateTeamLoading(state, action) {
      state.createTeamLoading = action.payload;
    },
    setTeamDetail(state, action) {
      state.teamDetails = action.payload;
    },
    setInvitationLoader(state, action) {
      state.invitationLoader = action.payload;
    },
    setRemoveMemberLoader(state, action) {
      state.removeMemberLoader = action.payload;
    },
    setNotificationList(state, action) {
      state.notificationList = action.payload;
    },
    setCheckNotificationStatus(state, action) {
      state.checkNotificationStatus = action.payload;
    },
    setAcceptJoinTeamLoader(state, action) {
      state.acceptJoinTeamLoader = action.payload;
    },
    setRejectJoinTeamLoader(state, action) {
      state.rejectJoinTeamLoader = action.payload;
    },
    setTeamMemberEmail(state, action) {
      state.teamMemberEmail = action.payload;
    },
    setNotificationLoader(state, action) {
      state.notificationLoader = action.payload;
    },
    setBoardAdsItems(state, action) {
      state.boardAdsItems.total = action.payload.total
        ? action.payload.total
        : 0;
      state.boardAdsItems.data = action.payload.data;
    },
    setSharedBoardAdsItems(state, action) {
      state.sharedBoardAdsItems.total = action.payload.total
        ? action.payload.total
        : 0;
      state.sharedBoardAdsItems.data = action.payload.data;
      // state.sharedBoardAdsItems = action.payload;
    },
    setFolderAccessMemberList(state, action) {
      state.folderAccessMemberList = action.payload;
    },
    setFolderTeamMemberList(state, action) {
      state.folderTeamMemberList = action.payload;
    },
    setIsRemoveUserModalOpen(state, action) {
      state.isRemoveUserModalOpen = action.payload;
    },
    setSharedFolderList(state, action) {
      state.sharedFolderList = action.payload;
    },
    setSelectedSortOption(state, action) {
      state.filterOptions.sortValue = action.payload.sortValue;
      state.filterOptions.sort = action.payload.sortObject;
    },
    setFilterOptionLimitValue(state, action) {
      state.filterOptions = {
        ...state.filterOptions,
        limit: action.payload,
      };
    },
    setUserOptionLimitValue(state, action) {
      state.usersModalOptions = {
        userLimit: 10,
        skip: action.payload,
      };
    },
    setSelectedFilterOptions<T extends keyof FilterOptions>(
      state: any,
      action: PayloadAction<SetFilterOptionsPayload<T>>
    ) {
      const { selectAll, checked, id, list, stateName } = action.payload;
      const { filterOptions } = state;

      const selectedFilters = filterOptions[stateName] as string[];

      if (selectAll) {
        if (checked) {
          const mergedArray = [
            ...selectedFilters,
            ...list.map((item: listType) => item._id),
          ];
          const uniqueArray = mergedArray.filter(
            (value, index) => mergedArray.indexOf(value) === index
          );
          (filterOptions[stateName] as string[]) = uniqueArray;
        } else {
          (filterOptions[stateName] as string[]) = [];
        }
      } else {
        if (id) {
          const index = (filterOptions[stateName] as string[]).indexOf(id);
          if (checked && index === -1) {
            (filterOptions[stateName] as string[]).push(id);
          } else if (!checked && index !== -1) {
            (filterOptions[stateName] as string[]).splice(index, 1);
          }
        }
      }
    },
    setLoggingUserIdInFilterOption(state, action) {
      state.filterOptions.userId = action.payload;
    },
    setFilterOptionSkipValue(state, action) {
      state.filterOptions = {
        ...state.filterOptions,
        skip: action.payload,
      };
    },
    setFilterOptionBrandId(state, action) {
      state.filterOptions.selectedPageIds = [action.payload];
    },
    resetSwipefileState(state) {
      //Swipe file ads listing reset state
      state.boardAdsItems.data = [];
      state.boardAdsItems.total = 0;
      state.sharedBoardAdsItems.data = [];
      state.sharedBoardAdsItems.total = 0;
      state.filterOptions.selectedPlatformIds = [];
      state.filterOptions.selectedPageIds = [];
      state.filterOptions.selectedBoardIds = [];
      state.filterOptions.limit = 30;
      state.filterOptions.skip = 0;
      state.filterOptions.sortValue = "Newest";
      state.filterOptions.sort = {
        createdAt: -1,
      };
      state.filterOptions.selectedTagIds = [];
    },
    resetSkipLimitValue(state) {
      state.filterOptions.limit = 10;
      state.filterOptions.skip = 0;
      state.filterOptions.userLimit = 10;
    },
    setBoardAdsSearchValue(state, action) {
      state.search = action.payload;
    },
    setSelectedSharedFolder(state, action) {
      state.selectedSharedFolder = action.payload;
    },
    setRemoveTeamMember(state, action) {
      state.removeTeamMember = action.payload.userId;
    },
    setSelectedTabIndex(state, action) {
      state.selectedTabIndex = action.payload;
    },
    setRemoveTeamLoader(state, action) {
      state.removeTeamLoader = action.payload;
    },
    setOpenRegisterNowModal(state, action) {
      state.openRegisterNowModal = action.payload;
    },
  },
});

export const {
  actions: MetaDataReducerAction,
  name: MetaDataReducerName,
  reducer: MetaDataReducer,
} = metaData;
