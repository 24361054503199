import { call, put, select } from "redux-saga/effects";
import { createSliceSaga } from "../saga-helper";
import * as Sentry from "@sentry/react";

import {AxiosResponse} from "axios";
import {getArchiveFileAdsList} from "../../services/ArchiveServices";
import {archivefileFilterOptionSelector, archiveFilePageSearchSelector} from "./selector";
import {FilterOptions} from "../swipeFile/types";
import {ArchiveReducerAction} from "./slice";


const saga = createSliceSaga({
    name: "archive-state",
    caseSagas: {
        *fetchArchiveFileList(action: {
            type: string;
            payload: {
                userId: string;
            };
        }) {
            try {
                yield put(
                    ArchiveReducerAction.setLoggingUserIdInFilterOption(
                        action.payload.userId
                    )
                );
                const filterOptions: FilterOptions = yield select(archivefileFilterOptionSelector);

                yield put(ArchiveReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(getArchiveFileAdsList, filterOptions);

                if (response?.status === 200) {
                    yield put(ArchiveReducerAction.setArchiveFileItems(response?.data));
                    yield put(
                        ArchiveReducerAction.setFilterOptionSkipValue(
                            filterOptions.skip + filterOptions.limit
                        )
                    );
                    yield put(ArchiveReducerAction.setIsLoading(false));
                }
            } catch (error) {
                yield put(ArchiveReducerAction.setIsLoading(false));
                Sentry.captureException(error);
                throw new Error();
            }
        },

        *fetchSearchedArchiveFileItema() {
            try {
                yield put(ArchiveReducerAction.setFilterOptionSkipValue(0));
                yield put(ArchiveReducerAction.setArchiveFileItemToInitialSet([]));
                const search: string = yield select(archiveFilePageSearchSelector);
                const data: FilterOptions = yield select(archivefileFilterOptionSelector);

                yield put(ArchiveReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(getArchiveFileAdsList, {
                    ...data,
                    search: search,
                });
                if (response?.status === 200) {
                    yield put(ArchiveReducerAction.setArchiveFileItems(response?.data));
                    yield put(
                        ArchiveReducerAction.setFilterOptionSkipValue(
                            data.skip + data.limit
                        )
                    );
                    yield put(ArchiveReducerAction.setIsLoading(false));
                }
            } catch (error) {
                yield put(ArchiveReducerAction.setIsLoading(false));
                Sentry.captureException(error);
                throw new Error();
            }
        },

        *fetchSortedSwipeFileList() {
            try {
                yield put(ArchiveReducerAction.setFilterOptionSkipValue(0));
                yield put(ArchiveReducerAction.setArchiveFileItemToInitialSet([]));
                const data: FilterOptions = yield select(archivefileFilterOptionSelector);

                yield put(ArchiveReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(getArchiveFileAdsList, data);

                if (response?.status === 200) {
                    yield put(ArchiveReducerAction.setArchiveFileItems(response?.data));
                    yield put(
                        ArchiveReducerAction.setFilterOptionSkipValue(
                            data.skip + data.limit
                        )
                    );
                    yield put(ArchiveReducerAction.setIsLoading(false));
                }
            } catch (error) {
                yield put(ArchiveReducerAction.setIsLoading(false));
                Sentry.captureException(error);
                throw new Error();
            }
        },

        *applyFilterOnArchivedFileList(action: any) {
            try {
                yield put(ArchiveReducerAction.setFilterOptionSkipValue(0));
                yield put(ArchiveReducerAction.setArchiveFileItemToInitialSet([]));
                yield put(
                    ArchiveReducerAction.setSelectedFilterOptions(action.payload)
                );
                const data: FilterOptions = yield select(archivefileFilterOptionSelector);

                yield put(ArchiveReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(getArchiveFileAdsList, data);
                if (response?.status === 200) {
                    yield put(ArchiveReducerAction.setArchiveFileItems(response?.data));
                    yield put(
                        ArchiveReducerAction.setFilterOptionSkipValue(
                            data.skip + data.limit
                        )
                    );
                    yield put(ArchiveReducerAction.setIsLoading(false));
                }
            } catch (error) {
                yield put(ArchiveReducerAction.setIsLoading(false));
                Sentry.captureException(error);
                throw new Error();
            }
        },
    },
    sagaType: "takeEvery",
});

export const {
    actions: ArchivedSagaActions,
    saga: ArchivedSaga,
    name: ArchivedSagaName,
} = saga;
