import { Avatar, Badge, Card, Stack, Typography } from "@mui/material";
import {
  CardContainer,
  CardDetailsWrapper,
  CardHeader,
  CardImage,
  CardMediaWrapper,
  VideoPlayButton,
  VideoMuteButton,
} from "./style";
import { useTheme } from "styled-components";
import { useEffect, useRef, useState } from "react";
import {
  MuteIcon,
  PauseIcon,
  PlayIcon,
  UnmuteIcon,
} from "../../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataReducerAction } from "../../../store/metadata/slice";
import { AdCardProps } from "../../../utils/propTypes";
import { formatCreatedDate } from "../../../utils/helpers";
import ImageSkeleton from "../../../assets/images/LoaderImage.jpg";
import { currentPlayingVideoSelector } from "../../../store/metadata/selector";
import AdSkeleton from "../adSkeleton";

const PublicAdCard = ({ item }: AdCardProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [startVideo, setStartVideo] = useState(false);
  const [muteVideo, setMuteVideo] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const { currentPlayingVideo, setPlayButtonForCurrentVideo } = useSelector(
    currentPlayingVideoSelector
  );

  const videoRef = useRef<HTMLVideoElement>(null);

  // const handleVideoPlay = () => {
  //   if (videoRef.current) {
  //     const video = videoRef.current;
  //     const isPlaying =
  //       video.currentTime > 0 &&
  //       !video.paused &&
  //       !video.ended &&
  //       video.readyState > video.HAVE_CURRENT_DATA;

  //     if (!isPlaying) {
  //       setStartVideo(true);
  //       if (currentPlayingVideo && !(video === currentPlayingVideo)) {
  //         //Pause previous video before playing next
  //         currentPlayingVideo.pause();
  //         setPlayButtonForCurrentVideo(false);
  //       }
  //       dispatch(
  //         MetaDataReducerAction.setCurrentPlayingVideo({ video, setStartVideo })
  //       );
  //       video.play();
  //     } else {
  //       setStartVideo(false);
  //       video.pause();
  //     }
  //   }
  // };

  // const handleVideoMute = () => {
  //   if (videoRef.current) {
  //     const video = videoRef.current;
  //     if (video.volume === 0) {
  //       setMuteVideo(false);
  //       video.volume = 1;
  //     } else {
  //       setMuteVideo(true);
  //       video.volume = 0;
  //     }
  //   }
  // };

  useEffect(() => {
    // Reset video controls when item changes
    setStartVideo(false);
    setMuteVideo(false);
    if (currentPlayingVideo) {
      currentPlayingVideo.pause();
      setPlayButtonForCurrentVideo(false);
      dispatch(
        MetaDataReducerAction.setCurrentPlayingVideo({
          video: null,
          setStartVideo: false,
        })
      );
    }
  }, [item]);

  return (
    <CardContainer>
      {!item?._id ? (
        <AdSkeleton />
      ) : (
        <>
          <CardHeader>
            <CardDetailsWrapper>
              <div>
                <Avatar
                  src={item?.page?.logo}
                  sx={{
                    width: "2.25rem",
                    height: "2.25rem",
                    cursor: "unset",
                  }}
                ></Avatar>
              </div>
              <Stack width={"100%"}>
                <div>
                  <Typography
                    fontSize="1rem"
                    color={theme.colors.black2}
                    fontWeight={700}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                    sx={{
                      cursor: "unset",
                    }}
                  >
                    {item.page.name}
                  </Typography>
                </div>
                <Stack
                  direction="row"
                  display={"flex"}
                  sx={{ alignItems: "baseline" }}
                  gap={"1.5rem"}
                >
                  <Badge
                    variant="dot"
                    sx={{
                      ".MuiBadge-badge": {
                        top: "-0.35rem",
                        left: "-0.188rem",
                        backgroundColor: theme.colors.green,
                        boxShadow: `0 0 0 0.125rem rgba(59, 176, 113, 0.2)`,
                        height: "0.5rem",
                        minWidth: "0.5rem",
                      },
                    }}
                  />
                  <Typography
                    fontSize="0.938rem"
                    color={theme.colors.gray1}
                    fontWeight={400}
                  >
                    {formatCreatedDate(item.createdAt)}
                  </Typography>
                </Stack>
              </Stack>
            </CardDetailsWrapper>
          </CardHeader>

          <CardMediaWrapper>
            <Card
              sx={{
                width: "100%",
                borderRadius: "0 0 10px 10px",
              }}
            >
              {item.imageUrls[0] && (
                <CardImage
                  src={item.imageUrls[0] ?? ImageSkeleton}
                  alt={"ad"}
                  loading="lazy"
                  style={{
                    objectFit: "fill",
                  }}
                />
              )}
              {item?.videoUrls[0] && (
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <video
                    ref={videoRef}
                    height="100%"
                    width="100%"
                    onEndedCapture={() => setStartVideo(false)}
                    key={item.videoUrls[0]}
                    style={{ display: "flex" }}
                    controls
                  >
                    <source src={item.videoUrls[0]} type="video/webm" />
                  </video>
                </div>
              )}
            </Card>
          </CardMediaWrapper>
        </>
      )}
    </CardContainer>
  );
};

export default PublicAdCard;
