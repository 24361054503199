import axiosClient from "../apiClient";
import { SortPopoverType } from "../types/Popover";

export const getArchiveFileAdsList = async (action: SortPopoverType) => {
    try {
        let response;
        let reqParameters = {
            skip: action.skip,
            limit: action.limit,
            filters: {
                ...(Array.isArray(action.selectedBoardIds) &&
                    action.selectedBoardIds.length > 0 && {
                        boardId: action.selectedBoardIds,
                    }),
                ...(Array.isArray(action.selectedPageIds) &&
                    action.selectedPageIds.length > 0 && {
                        pageId: action.selectedPageIds,
                    }),
                ...(Array.isArray(action.selectedPlatformIds) &&
                    action.selectedPlatformIds.length > 0 && {
                        platforms: action.selectedPlatformIds,
                    }),
                ...(Array.isArray(action.selectedTagIds) &&
                    action.selectedTagIds.length > 0 && {
                        tags: action.selectedTagIds,
                    }),
                ...(action.folderId && { folderId: action.folderId }),
                ...(action.userId && { userId: action.userId }),
                isArchived: true
            },
            ...(action.search && { search: action.search }),
            sort: action.sort,
        };
        response = await axiosClient.post("/ad/archive/ad/list", reqParameters);
        return response;
    } catch (error) {
        return error;
    }
};
