import SelectDropdown from "../../selectDropdown";
import {BoardIcon, FolderIconImage} from "../../../assets/Icons";
import {
    Box,
    Chip,
    CircularProgress, Collapse, Divider, List, ListItem, ListItemText, MenuItem,
    SelectChangeEvent,
    Stack, Typography,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import {StyledChip, StyledMenuItem, StyledSubheader} from "../../../globalStyle";
import FilterSearchbox from "../filterSearchbox";
import { SelectBoardDropdownProps } from "../../../utils/propTypes";
import {useEffect, useMemo, useState} from "react";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
    boardItemsSelector, folderListsSelector,
    loadingBoardSelector,
    openBoardDropdownSelector,
} from "../../../store/metadata/selector";
import { BoardItem } from "../../../store/metadata/types";
import { ButtonText, HeaderButton2 } from "../../../pageComponents/style";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import { MetaDataReducerAction } from "../../../store/metadata/slice";
import React from "react";

const SelectBoardDropdown = ({
  showSelectedItemCount,
  selectedBoards,
  handleUpdateBoard,
  selectedBoardIds = [],
  isDisabled,
}: SelectBoardDropdownProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [boards, setBoards] = useState<string[]>([]);
  const [boardSearchText, setBoardSearchText] = useState("");
  const folderList = useSelector(folderListsSelector)
  const boardItems = useSelector(boardItemsSelector);
  const loading = useSelector(loadingBoardSelector);
  const openBoardDropdown = useSelector(openBoardDropdownSelector);
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>({});

    const toggleFolder = (folderId: string) => {
        setOpenFolders((prev) => ({
            ...prev,
            [folderId]: !prev[folderId],
        }));
    };
    // const addFolder = useMemo(() => {
    //     return (folderList?.userFolders || [])?.concat?.(folderList?.teamFolders).concat(folderList?.sharedFolders)?.map((value: any) => ({
    //         name:value?.name,
    //         boards:value?.boards
    //     }));
    //
    // }, [folderList?.userFolders, folderList?.teamFolders, folderList?.sharedFolders]);

  const handleBoardSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBoardSearchText(event.target.value);
    // if (event.target.value === "") {
    //   dispatch(
    //     MetaDataSagaActions.fetchBoardList({ search: event.target.value })
    //   );
    // }
  };

  // const handleBoardSearch = () => {
  //   dispatch(MetaDataSagaActions.fetchBoardList({ search: boardSearchText }));
  // };

  // const handleBoardSearchKeyDown: React.KeyboardEventHandler<
  //   HTMLInputElement
  // > = (event) => {
  //   event.stopPropagation();
  //   if (event.key === "Enter") handleBoardSearch();
  // };

  const handleDropDownClose = () => {
    if (boardSearchText) {
      dispatch(MetaDataSagaActions.fetchBoardList({ search: "" }));
      setBoardSearchText("");
    }
    dispatch(MetaDataReducerAction.setOpenBoardDropdown(""));
  };

    // const folderMenuItems = useMemo(() => {
    //     const safeFolders = Array.isArray(addFolder) ? addFolder : [];
    //     return safeFolders.flatMap((folder: any, folderIndex: number) => [
    //         <StyledSubheader key={folderIndex}>{folder?.name}</StyledSubheader>,
    //         ...(folder?.boards?.map((board: any, boardIndex: number) => (
    //             <StyledMenuItem key={boardIndex} value={board?._id}>
    //                 {board?.name}
    //             </StyledMenuItem>
    //         )) ?? [])
    //     ]);
    // }, [addFolder]);




    const renderFolders = (folders: any[],boardSearchText:string, title: string) => {
        const filteredFolders = folders?.map((folder) => {
            return {
                ...folder,
                boards: folder.boards?.filter((board: any) => board.name.toLowerCase().includes(boardSearchText.toLowerCase()))
            }
        }).filter((folder) => {
            return boardSearchText?.length ? folder.boards?.length : true
        })
        return (
            <>
                <Stack direction="row" alignItems="center" gap={1} >

                    <Typography variant="subtitle2"
                                sx={{pl: 1, fontSize: '16px', paddingLeft:'6px', fontWeight: 700, color: 'gray'}}>
                        {title}
                    </Typography>
                    <Divider sx={{flex: 1}}/>
                </Stack>
                <List component="nav" >
                    {filteredFolders?.length ? filteredFolders?.map((folder) => (
                        <div key={folder?._id}>
                            <ListItem  style={{padding:'0 0 0 0.938rem'}} button onClick={() => toggleFolder(folder._id)}>
                                {/*<FolderIcon color="primary" sx={{mr: 1}}/>*/}
                                <FolderIconImage />
                                <ListItemText style={{paddingLeft:'7px',color:'black'}} primary={folder?.name}/>
                            </ListItem>
                            <Collapse in={openFolders[folder?._id]} timeout="auto" unmountOnExit>
                                <List style={{gap:'0px'}}  component="div" disablePadding sx={{pl: 1}}>
                                    {folder?.boards?.length ?
                                        folder?.boards?.map((board: any) => (
                                        <StyledMenuItem
                                            style={{margin:'0px', padding: '7px 7px 7px 30px',color: 'black'}}
                                            sx={{gap: '6px'}}
                                            key={board?._id}
                                            value={board?._id}
                                            selected={boards.includes(board?._id)}
                                            onClick={() => {
                                                const newBoards = boards?.includes(board?._id)
                                                    ? boards?.filter((id) => id !== board?._id)
                                                    : [...boards, board?._id];
                                                setBoards(newBoards);
                                            }}
                                        >
                                            <BoardIcon fill={'black'}/>
                                            {board.name}
                                        </StyledMenuItem>
                                        )) : <>
                                            <Typography style={{color:'gray'}} align={'center'} variant={'body2'}>No Board</Typography>
                                        </>}
                                </List>
                            </Collapse>
                        </div>
                    ))
                        : <Typography pb={2} style={{color:'black'}} align={'center'} variant={'body2'} color={'gray'}>No Folders Found</Typography>}
                </List>
            </>
        )
    };

  useEffect(() => {
    selectedBoards?.length > 0 &&
      setBoards(selectedBoards?.map((board) => board._id));
  }, [selectedBoards]);

  return (
    <SelectDropdown
      openDropdown={openBoardDropdown}
      startAdornmentIcon={<BoardIcon fill={theme.colors.gray1} />}
      multiple={true}
      renderValue={(selected: string[]) => {
        return (
          <Stack
            direction="row"
            justifyContent={
              showSelectedItemCount === 1 ? "space-between" : "start"
            }
          >
            {boards.slice(0, showSelectedItemCount).map((item: string) => {
              let findBoard;
              findBoard = selectedBoards?.find(
                (ele: BoardItem) => ele._id === item
              );
              if (!findBoard) {
                findBoard = boardItems?.find(
                  (ele: BoardItem) => ele._id === item
                );
              }
              return (
                findBoard && <Chip key={findBoard._id} label={findBoard.name} />
              );
            })}
            {boards?.length > showSelectedItemCount && (
              <StyledChip label={`+${boards.length - showSelectedItemCount}`} />
            )}
          </Stack>
        );
      }}
      handleChange={(event: SelectChangeEvent) => {
        const selectedValue = event.target.value;
        if (typeof selectedValue === "string") {
          setBoards(selectedValue.split(","));
        } else {
          const parsedValues = (selectedValue as string[])?.filter(
            (item) => item !== undefined
          );
          setBoards(parsedValues);
        }
      }}
      value={boards ?? []}
      handleClose={handleDropDownClose}
      height="20rem"
      menuListPadding="0"
    >
      <Box
        sx={{
          padding: "0.938rem 0.938rem 0 0.938rem",
          marginBottom: "0.938rem",
          position: "sticky",
          top: 0,
          left: 0,
          background: "white",
          borderBottom: `1px solid ${theme.colors.border1}`,
          zIndex: 1,
        }}
        onKeyDown={(event) => event.stopPropagation()}
      >
        <FilterSearchbox
          placeholder="Search"
          handleChange={handleBoardSearchOnChange}
          // handleKeyDown={handleBoardSearchKeyDown}
          // handleSearchIconClick={handleBoardSearch}
        />
      </Box>
        {/*{folderMenuItems}*/}
        {renderFolders(folderList?.userFolders,boardSearchText, "User Folders")}
        {renderFolders(folderList?.teamFolders,boardSearchText, "Team Folders")}
        {renderFolders(folderList?.sharedFolders,boardSearchText, "Shared Folders")}
      {/*{boardItems?.map((board: BoardItem, index: number) => (*/}
      {/*  <StyledMenuItem key={index} value={board._id}>*/}
      {/*    {board.name}*/}
      {/*  </StyledMenuItem>*/}
      {/*))}*/}
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          background: "white",
          zIndex: 1,
          padding: "0.625rem",
          borderTop: `1px solid ${theme.colors.border1}`,
          textAlign: "right",
        }}
      >
        <HeaderButton2
          variant="contained"
          size="small"
          onClick={() => {
            handleUpdateBoard(
              boards,
              selectedBoardIds?.filter((id) => !boards.includes(id))
            );
          }}
          disabled={isDisabled && !boards?.length}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          <ButtonText>Update</ButtonText>
        </HeaderButton2>
      </Box>
    </SelectDropdown>
  );
};

export default SelectBoardDropdown;
