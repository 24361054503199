import {
  Badge,
  Box,
  Card,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  ExtensionBoxWrapper,
  ExtensionLogoWrapper,
  PlatformIconWrapper,
  PlatformsWrapper,
  StyledContainedButton,
  StyledOutlinedButton,
} from "./style";
import {
  ArrowOutwardIcon,
  ChromeIcon,
  ExtensionLogo,
} from "../../assets/Icons";
import { useTheme } from "styled-components";
import FacebookIcon from "../../assets/images/facebook-icon.png";
import InstagramIcon from "../../assets/images/instagram-icon.png";
import LinkedinIcon from "../../assets/images/linkedin-icon.png";
import { StyledDivider } from "../../globalStyle";
import { chromeExtensionUrl } from "../../utils/constants";
import { Link } from "react-router-dom";

const PlatformButton = ({
  name,
  icon,
  url,
}: {
  name: string;
  icon: string;
  url: string;
}) => {
  return (
    <Link to={url} target="_blank">
      <StyledOutlinedButton fullWidth padding="0.938rem 1.25rem">
        <PlatformIconWrapper src={icon} alt="" />
        {name}
        <Box display={"flex"} alignItems={"center"} marginLeft={"auto"}>
          <ArrowOutwardIcon fill="#414042" />
        </Box>
      </StyledOutlinedButton>
    </Link>
  );
};

const ChromeExtensionModal = () => {
  const theme = useTheme();

  return (
    <>
      <ExtensionBoxWrapper>
        <Stack direction="row" alignItems="start" gap="1.5rem">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={<ChromeIcon />}
            sx={{ ".MuiBadge-badge": { bottom: "5%", right: "0%" } }}
          >
            <ExtensionLogoWrapper>
              <ExtensionLogo />
            </ExtensionLogoWrapper>
          </Badge>
          <Stack gap="0.938rem">
            <Typography
              fontSize="1rem"
              fontWeight="500"
              color={theme.colors.gray1}
            >
              Our Chrome Extension is the best way to save ads to your Library.
              <br />
              Simply install the extension to display a “Save Button” accross
              multiple platforms.
            </Typography>
            <StyledContainedButton
              variant="contained"
              size="small"
              sx={{ padding: "0.5rem 1.25rem" }}
              onClick={() => {
                window.open(chromeExtensionUrl, "_blank");
              }}
            >
              Install Extension
            </StyledContainedButton>
          </Stack>
        </Stack>
      </ExtensionBoxWrapper>
      <Box>
        <Card
          sx={{
            width: "450px",
            height: "250px",
            margin: "0 1.875rem 1.875rem auto ",
            borderRadius: "10px",
          }}
        >
          <CardMedia
            component="iframe"
            title="YouTube video"
            src="https://www.youtube.com/embed/HjFH4wtcdZU"
            height="250px"
          />
        </Card>
      </Box>
      <StyledDivider />
      <PlatformsWrapper>
        <Typography
          fontSize="1.25rem"
          fontWeight="600"
          color={theme.colors.black2}
          sx={{ marginBottom: "1.875rem" }}
        >
          Platforms
        </Typography>
        <Grid container spacing={2.5}>
          <Grid item sm={4}>
            <PlatformButton
              name="Facebook Ad Library"
              icon={FacebookIcon}
              url="https://www.facebook.com/ads/library"
            />
          </Grid>
          <Grid item sm={4}>
            <PlatformButton
              name="LinkedIn Ad Library"
              icon={LinkedinIcon}
              url="https://www.linkedin.com/ad-library/home"
            />
          </Grid>
          <Grid item sm={4}>
            <PlatformButton
              name="Instagram"
              icon={InstagramIcon}
              url="https://www.instagram.com"
            />
          </Grid>
        </Grid>
      </PlatformsWrapper>
    </>
  );
};

export default ChromeExtensionModal;
