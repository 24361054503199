import {
  HamburgerMenu,
  HeaderLeftMenuSection,
  HeaderMenuSection,
  HeaderSubTitle,
  HeaderTitle,
  HeaderTitleSection,
} from "../../pageComponents/style";
import { MenuIcon, MenuOpenIcon } from "../../assets/Icons";
import useConfig from "../../hooks/useConfig";
import { HeaderProps } from "../../utils/propTypes";

const HeaderFolderPublicRoute = ({ content }: HeaderProps) => {
  const { drawerOpen, onChangeDrawer } = useConfig();

  const handleSidebarDrawer = () => {
    onChangeDrawer(!drawerOpen);
  };

  return (
    <HeaderMenuSection>
      <HeaderLeftMenuSection>
        <HamburgerMenu onClick={() => handleSidebarDrawer()}>
          {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
        </HamburgerMenu>
        <HeaderTitleSection>
          <HeaderTitle>{content?.tabTitle}</HeaderTitle>
          <HeaderSubTitle>{content?.tabSubTitle}</HeaderSubTitle>
        </HeaderTitleSection>
      </HeaderLeftMenuSection>
    </HeaderMenuSection>
  );
};

export default HeaderFolderPublicRoute;
