import {
  Box,
  Checkbox,
  CircularProgress, Collapse, Divider, List, ListItem, ListItemText,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "styled-components";
import FilterSearchbox from "../commonComponents/filterSearchbox";
import { StyledFormGroup } from "../popover/style";
import {BoardIcon, CheckedIcon, CreateNewIcon, FolderIconImage, UncheckedIcon} from "../../assets/Icons";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  boardIdsSelector,
  boardItemsSelector, folderListsSelector,
  isLoadingSelector,
  loadingBoardSelector,
  saveAdPopupAnchorElSelector,
  selectedAdIdSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { ButtonText, HeaderButton2 } from "../../pageComponents/style";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import useDebounce from "../../hooks/debounce";
import FolderIcon from "@mui/icons-material/Folder";
import {StyledMenuItem} from "../../globalStyle";

const SaveAdPopover = ({ reFetchData }: { reFetchData?: Function }) => {
  const [removedBoardId, setRemovedBoardId] = useState<string[]>([]);
  const [boardSearchText, setBoardSearchText] = useState("");
  const theme = useTheme();
  const dispatch = useDispatch();
  const boardItems = useSelector(boardItemsSelector);
  const selectedBoardIds = useSelector(boardIdsSelector);
  const folderList = useSelector(folderListsSelector)
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>({});
  const anchorEl = useSelector(saveAdPopupAnchorElSelector);
  const selectedAdId = useSelector(selectedAdIdSelector);

  const loadingBoard = useSelector(loadingBoardSelector);

  // Use debounce for the search text
  const debouncedSearchText = useDebounce(boardSearchText, 1000);

  const toggleFolder = (folderId: string) => {
    setOpenFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));
  };

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    boardId: string
  ) => {
    const { checked } = event.target;
    dispatch(MetaDataReducerAction.setBoardId(boardId));

    checked === false &&
      setRemovedBoardId((prevState) => [...prevState, boardId]);
  };

  const handlePopoverClose = () => {
    setRemovedBoardId([]);
    dispatch(MetaDataReducerAction.setSaveAdPopupAnchorEl(null));
    dispatch(MetaDataReducerAction.setBoardIdstoInitialSet([]));
    setBoardSearchText("");
    dispatch(MetaDataSagaActions.fetchBoardList({ search: "" }));
  };

  const handleSubmit = () => {
    dispatch(
      MetaDataSagaActions.saveAdToBoards({
        adId: selectedAdId,
        boardIds: selectedBoardIds,
        removeBoardIds: removedBoardId,
        reFetchData,
      })
    );
    setBoardSearchText("");
  };

  // Fetch board list when debounced search text changes
  useEffect(() => {
    if (debouncedSearchText) {
      dispatch(MetaDataSagaActions.fetchBoardList({ search: debouncedSearchText }));
    } else {
      // dispatch(MetaDataSagaActions.fetchBoardList({ search: "" }));
    }
  }, [debouncedSearchText, dispatch]);

  const handleBoardSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBoardSearchText(event.target.value);
  };

  // const handleBoardSearch = () => {
  //   dispatch(MetaDataSagaActions.fetchBoardList({ search: boardSearchText }));
  // };

  // const handleBoardSearchKeyDown: React.KeyboardEventHandler<
  //   HTMLInputElement
  // > = (event) => {
  //   event.stopPropagation();
  //   if (event.key === "Enter")
  //   setBoardSearchText(boardSearchText);
  // };

  // Separate selected and non-selected boards
  const selectedBoards = boardItems.filter((board: any) =>
    selectedBoardIds.includes(board._id)
  );
  const nonSelectedBoards = boardItems.filter(
    (board: any) => !selectedBoardIds.includes(board._id)
  );

  // Combine the two arrays, with selected boards at the top
  const sortedBoardItems = [...selectedBoards, ...nonSelectedBoards];

  const renderFolders = (folders: any[],boardSearchText:string, title: string) => {
    const filteredFolders = folders?.map((folder) => {
      return {
        ...folder,
        boards: folder.boards?.filter((board: any) => board.name.toLowerCase().includes(boardSearchText.toLowerCase()))
      }
    }).filter((folder) => {
      return boardSearchText?.length ? folder.boards?.length : true
    })
    return (
        <>
          <Stack direction="row" alignItems="center" sx={{gap: 1}}>

            <Typography variant="subtitle2"
                        sx={{pl: 1, fontSize: '16px', paddingLeft:'6px',fontWeight: 700, color: 'gray'}}>
              {title}
            </Typography>
            <Divider sx={{flex: 1}}/>
          </Stack>
          <List component="nav" style={{gap:'0px'}}>
            {filteredFolders?.length ? filteredFolders?.map((folder) => (
                    <div key={folder?._id}>
                        <ListItem style={{padding:'0 0 0 0.938rem'}} button onClick={() => toggleFolder(folder._id)}>
                        {/*<FolderIcon color="primary" sx={{mr: 1}}/>*/}
                          <FolderIconImage />
                        <ListItemText style={{paddingLeft:'7px',color:'black'}} primary={folder?.name}/>
                      </ListItem>
                      <Collapse in={openFolders[folder?._id]} timeout="auto" unmountOnExit>
                        <List style={{gap:'0px'}} component="div" disablePadding sx={{pl: 1}}>
                          {folder?.boards?.length ?
                              folder?.boards?.map((board: any) => (
                              <StyledMenuItem
                                  style={{margin:'0px', padding: '7px 7px 7px 30px',color: 'black'}}
                                  sx={{gap: '6px'}}
                                  key={board?._id}
                                  value={board?._id}
                                  selected={selectedBoardIds?.includes(board?._id)}
                                  onClick={() => {
                                    onChangeHandler({
                                      target: {
                                        checked: !selectedBoardIds?.includes(board?._id)
                                      }
                                    } as React.ChangeEvent<HTMLInputElement>, board?._id)
                                  }}

                              >
                                <BoardIcon fill={'black'}/>
                                {board.name}
                              </StyledMenuItem>
                          )) : <>
                                <Typography style={{color:'gray'}} align={'center'} variant={'body2'}>No Board</Typography>
                              </>}
                        </List>
                      </Collapse>
                    </div>
                ))
                : <Typography pb={2} style={{color:'black'}} align={'center'} variant={'body2'} color={'gray'}>No Folders Found</Typography>}
          </List>
        </>
    )
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "0.938rem",
          padding: "1.375rem 1.25rem 1.25rem 1.25rem",
          width: "23%",
        },
        ".MuiList-root": {
          padding: "0",
          display: "flex",
          flexDirection: "column",
          gap: "0.938rem",
        },
      }}
    >
      <Typography
        fontSize="1.125rem"
        fontWeight="700"
        color={theme.colors.black2}
      >
        {selectedBoardIds.length > 0 ? 'Saved' : 'Save'} to Board
      </Typography>
      <StyledFormGroup padding="0">
        <Box
            sx={{
              padding: "0 0.938rem 0 0.938rem",
              // marginBottom: "0.938rem",
              position: "sticky",
              top: 0,
              left: 0,
              background: "white",
              borderBottom: `1px solid ${theme.colors.border1}`,
              zIndex: 1,
            }}
            onKeyDown={(event) => event.stopPropagation()}
        >
          <FilterSearchbox
              placeholder="Search"
              value={boardSearchText}
              handleChange={handleBoardSearchOnChange}
              // handleKeyDown={handleBoardSearchKeyDown}
              // handleSearchIconClick={handleBoardSearch}
          />
        </Box>

        {renderFolders(folderList?.userFolders,boardSearchText, "User Folders")}
        {renderFolders(folderList?.teamFolders,boardSearchText, "Team Folders")}
        {renderFolders(folderList?.sharedFolders,boardSearchText, "Shared Folders")}
        {/*{sortedBoardItems?.map((board: any, index: any) => (*/}
        {/*  <Stack*/}
        {/*    key={index}*/}
        {/*    direction="row"*/}
        {/*    sx={{*/}
        {/*      alignItems: "center",*/}
        {/*      justifyContent: "space-between",*/}
        {/*      flexDirection: "row",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Typography*/}
        {/*      fontSize="0.938rem"*/}
        {/*      fontWeight="500"*/}
        {/*      color={theme.colors.black2}*/}
        {/*    >*/}
        {/*      {board.name}*/}
        {/*    </Typography>*/}
        {/*    <Checkbox*/}
        {/*      key={index}*/}
        {/*      onChange={(event) => onChangeHandler(event, board._id)}*/}
        {/*      icon={<UncheckedIcon />}*/}
        {/*      checkedIcon={<CheckedIcon />}*/}
        {/*      sx={{ padding: 0 }}*/}
        {/*      checked={selectedBoardIds.includes(board._id)}*/}
        {/*    />*/}
        {/*  </Stack>*/}
        {/*))}*/}
      </StyledFormGroup>
      <HeaderButton2
        variant="contained"
        size="small"
        disabled={loadingBoard || selectedBoardIds?.length === 0}
        onClick={handleSubmit}
        startIcon={loadingBoard && <CircularProgress size={20} color="inherit" />}
      >
        <ButtonText>Update</ButtonText>
      </HeaderButton2>
      <Stack
        direction="row"
        sx={{ alignItems: "center", gap: "0." }}
        onClick={() => dispatch(MetaDataReducerAction.setOpenBoardModal(true))}
      >
        <CreateNewIcon fill={theme.colors.black2} />
        <Typography
          fontSize="1rem"
          fontWeight="600"
          color={theme.colors.black2}
          style={{
            cursor: "pointer",
          }}
        >
          Create New Board
        </Typography>
      </Stack>
    </Menu>
  );
};

export default memo(SaveAdPopover);
