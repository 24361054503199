import {useCallback, useEffect, useRef, useState} from "react";

const useScrollingUp = () => {
  const [isSticky, setIsSticky] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const toggleSticky = useCallback(
    ({ bottom }: { bottom: number }) => {
      if (bottom <= 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    },
    [isSticky]
  );

  const handleScroll = () => {
    if (scrollRef.current !== null) {
      toggleSticky(scrollRef.current.getBoundingClientRect());
    }
  };

    useEffect(() => {
        const scrollElement: any = document.getElementById('content');
        (scrollElement || window).addEventListener("scroll", handleScroll);
        return () => {
            (scrollElement || window).removeEventListener("scroll", handleScroll);
        };
    }, [toggleSticky]);

    return {scrollRef, isSticky, handleScroll};
};

export default useScrollingUp;
