import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {FilterOptions, SetFilterOptionsPayload, SwipeFileStateTypes, ViewTypeEnum} from "../swipeFile/types";
import {listType} from "../metadata/types";


export const initialState: any = {
    archiveFileListItems: {
        data: [],
        total: 0,
    },
    isLoading: false,
    selectedSidebarBoardId: "",
    search: "",
    swipeFilesFavouriteBrandFilterEnabled: false,
    filterOptions: {
        selectedPlatformIds: [],
        selectedPageIds: [],
        selectedBoardIds: [],
        userId: "",
        limit: 30,
        skip: 0,
        sortValue: "Newest",
        sort: {
            createdAt: -1,
        },
        selectedTagIds: [],
    },
    viewType: ViewTypeEnum.Card,

};

const archive = createSlice({
    name: "archiveState",
    initialState,
    reducers: {
        setArchiveFileItemToInitialSet(state, action) {
            state.archiveFileListItems.data = [];
            state.archiveFileListItems.total = 0;
        },

        setSelectedSortOption(state, action) {
            state.filterOptions.sortValue = action.payload.sortValue;
            state.filterOptions.sort = action.payload.sortObject;
        },

        setLoggingUserIdInFilterOption(state, action) {
            state.filterOptions.userId = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setArchiveFileItems(state, action) {
            state.archiveFileListItems.total = action.payload.total
                ? action.payload.total
                : 0;

            const mergedData = [...(state.archiveFileListItems.data || []), ...action.payload.data];

            // Create a new Set based on a unique identifier (e.g., id)
            const uniqueData = Array.from(new Set(mergedData.map(item => item._id)))
                .map(id => mergedData.find(item => item._id === id));

            state.archiveFileListItems.data = uniqueData;
        },

        setFilterOptionSkipValue(state, action) {
            state.filterOptions = {
                ...state.filterOptions,
                skip: action.payload,
            };
        },

        setViewType(state, action) {
            state.viewType = action.payload;
        },
        setSelectedTagIds(state, action) {
            state.filterOptions.selectedTagIds = action.payload;
        },

        resetarchivefileState(state) {
            //Swipe file ads listing reset state
            state.archiveFileListItems.data = [];
            state.archiveFileListItems.total = 0;
            state.filterOptions.selectedPlatformIds = [];
            state.filterOptions.selectedPageIds = [];
            state.filterOptions.selectedBoardIds = [];
            state.filterOptions.limit = 30;
            state.filterOptions.skip = 0;
            state.filterOptions.sortValue = "Newest";
            state.filterOptions.sort = {
                createdAt: -1,
            };
            state.filterOptions.selectedTagIds = [];
            state.selectedSidebarBoardId = "";
            state.swipeFilesFavouriteBrandFilterEnabled = false;
            state.swipeFileTabIndex = 0;
        },

        setArchiveFilePageSearchInputValue(state, action) {
            state.search = action.payload;
        },

        setUpdateArchiveFileItem(state, action) {
            state.archiveFileListItems.data = state.archiveFileListItems?.data.filter(
                (item:any) => item?._id !== action?.payload
            );
            state.archiveFileListItems.total -= 1;

        },

        setSelectedFilterOptions<T extends keyof FilterOptions>(
            state: SwipeFileStateTypes,
            action: PayloadAction<SetFilterOptionsPayload<T>>
        ) {
            const { selectAll, checked, id, list, stateName } = action.payload;
            const { filterOptions } = state;

            const selectedFilters = filterOptions[stateName] as string[];
            if (selectAll) {
                if (checked) {
                    const mergedArray = [
                        ...selectedFilters,
                        ...list.map((item: listType) => item._id),
                    ];
                    const uniqueArray = mergedArray.filter(
                        (value, index) => mergedArray.indexOf(value) === index
                    );
                    (filterOptions[stateName] as string[]) = uniqueArray;
                } else {
                    (filterOptions[stateName] as string[]) = [];
                }
            } else {
                    const index = (filterOptions[stateName] as string[]).indexOf(id);
                if (checked && index === -1) {
                    (filterOptions[stateName] as string[]).push(id);
                } else if (!checked && index !== -1) {
                    (filterOptions[stateName] as string[]).splice(index, 1);
                }
            }
        },
    },
});

export const {
    actions: ArchiveReducerAction,
    name: ArchiveReducerName,
    reducer: ArchiveReducer,
} = archive;
