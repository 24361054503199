import {
  Avatar,
  Badge,
  Box,
  Button,
  Card, CircularProgress,
  Link, Menu, MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CardContainer,
  CardDetailsWrapper,
  CardHeader,
  CardImage,
  CardMediaWrapper,
  SaveAdButton,
  ImageOverlay,
  VideoPlayButton,
  SaveAdButtonWrapper,
  CardDescription,
  SeeMoreButton,
  ViewDeatilsButton,
  VideoMuteButton,
  RedirectionButton, ViewDeatilsButtonWithouthover,
} from "./style";
import { useTheme } from "styled-components";
import { useEffect, useRef, useState } from "react";
import {
  ArrowOutwardIcon,
  AdEyeIcon,
  MuteIcon,
  PauseIcon,
  PlayIcon,
  UnmuteIcon,
  AdShareIcon, ArchivedIcon, EllipsisIcon, VisibleIcon, DeleteIcon, EyeIcon, EyeIconMenu,
} from "../../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataReducerAction } from "../../../store/metadata/slice";
import { AdCardProps } from "../../../utils/propTypes";
import { formatCreatedDate, notify, ToastTypes } from "../../../utils/helpers";
import ImageSkeleton from "../../../assets/images/LoaderImage.jpg";
import {currentPlayingVideoSelector, saveAdPopupAnchorElSelector} from "../../../store/metadata/selector";
import AdSkeleton from "../adSkeleton";
import { CustomTooltip } from "../customTooltip";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {userDetailsSelector} from "../../../store/account/selector";
import {SwipeFileReducerAction} from "../../../store/swipeFile/slice";

const AdCard = ({
  item,
  adDetailsCard,
  index,
  dataList,
  showRedirectIcon,
  hideSaveButton,
  handleViewAd,
  locationPathname,
  handleArchived,
  ArchiveShow = false,
  isBoardAdsData = false,
}: AdCardProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [startVideo, setStartVideo] = useState(false);
  const [muteVideo, setMuteVideo] = useState(false);
  const [seeMoreDescription, setSeeMoreDescription] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const user = useSelector(userDetailsSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = () => {setAnchorEl(null)};
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const { currentPlayingVideo, setPlayButtonForCurrentVideo } = useSelector(
    currentPlayingVideoSelector
  );

  const handleSaveButtonClick = (event: React.SyntheticEvent) => {
    dispatch(MetaDataReducerAction.setSaveAdPopupAnchorEl(event.currentTarget));
    dispatch(MetaDataReducerAction.setSelectedSaveAdId(item._id));
    dispatch(MetaDataReducerAction.setAdSelectedBoardIds(item.board));
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  const isDetailsPage = location.pathname.includes("adDetails");

  //custom play/pause and mute/unmute methods
  // const handleVideoPlay = () => {
  //   if (videoRef.current) {
  //     const video = videoRef.current;
  //     const isPlaying =
  //       video.currentTime > 0 &&
  //       !video.paused &&
  //       !video.ended &&
  //       video.readyState > video.HAVE_CURRENT_DATA;

  //     if (!isPlaying) {
  //       setStartVideo(true);
  //       if (currentPlayingVideo && !(video === currentPlayingVideo)) {
  //         //Pause previous video before playing next
  //         currentPlayingVideo.pause();
  //         setPlayButtonForCurrentVideo(false);
  //       }
  //       dispatch(
  //         MetaDataReducerAction.setCurrentPlayingVideo({ video, setStartVideo })
  //       );
  //       video.play();
  //     } else {
  //       setStartVideo(false);
  //       video.pause();
  //     }
  //   }
  // };

  // const handleVideoMute = () => {
  //   if (videoRef.current) {
  //     const video = videoRef.current;
  //     if (video.volume === 0) {
  //       setMuteVideo(false);
  //       video.volume = 1;
  //     } else {
  //       setMuteVideo(true);
  //       video.volume = 0;
  //     }
  //   }
  // };

  const handleDelete = (id:string) => {
    setAnchorEl(null);
    dispatch(SwipeFileReducerAction.setDeleteAdsModal(true));
    dispatch(SwipeFileReducerAction.setSelectedDeleteAdId(id));
  };

  const handleSeeMore = () => {
    setSeeMoreDescription(!seeMoreDescription);
  };

  useEffect(() => {
    // Reset video controls when item changes
    setStartVideo(false);
    setMuteVideo(false);
    if (currentPlayingVideo) {
      currentPlayingVideo.pause();
      setPlayButtonForCurrentVideo(false);
      dispatch(
        MetaDataReducerAction.setCurrentPlayingVideo({
          video: null,
          setStartVideo: false,
        })
      );
    }
  }, [item]);

  return (
    <>
      <CardContainer style={{backgroundColor:item?.isArchived ? '#E3E3E3' : 'white'}}>
        {!item?._id ? (
          <AdSkeleton />
        ) : (
          <>
            <CardHeader>
              <CardDetailsWrapper>
                <div onClick={() => handleViewAd && handleViewAd(item?.pageId)}>
                  <Avatar
                    src={item?.page?.logo}
                    sx={{
                      width: "2.25rem",
                      height: "2.25rem",
                      cursor: handleViewAd ? "pointer" : "unset",
                    }}
                  ></Avatar>
                </div>
                <Stack width={"100%"}>
                  <div
                    onClick={() => handleViewAd && handleViewAd(item?.pageId)}
                  >
                    <Typography
                      fontSize="1rem"
                      color={theme.colors.black2}
                      fontWeight={700}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                      sx={{
                        cursor: handleViewAd ? "pointer" : "unset",
                      }}
                    >
                      {item.page.name}
                    </Typography>
                  </div>
                  <Stack
                    direction="row"
                    display={"flex"}
                    sx={{ alignItems: "baseline" }}
                    gap={"1.5rem"}
                  >
                    <Badge
                      variant="dot"
                      sx={{
                        ".MuiBadge-badge": {
                          top: "-0.35rem",
                          left: "-0.188rem",
                          backgroundColor: theme.colors.green,
                          boxShadow: `0 0 0 0.125rem rgba(59, 176, 113, 0.2)`,
                          height: "0.5rem",
                          minWidth: "0.5rem",
                        },
                      }}
                    />
                    <Typography
                      fontSize="0.938rem"
                      color={theme.colors.gray1}
                      fontWeight={400}
                    >
                      {formatCreatedDate(item.createdAt)}
                    </Typography>
                  </Stack>
                </Stack>
              </CardDetailsWrapper>
              <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{marginLeft: "0.5rem"}}
              >

                {showRedirectIcon && item.adTargetSourceURL && (
                    <CustomTooltip title="Redirect to the Ad" id="ad_card">
                      <RedirectionButton
                          target={"_blank"}
                          href={item.adTargetSourceURL}
                      >
                        <ArrowOutwardIcon fill={theme.colors.black2}/>
                      </RedirectionButton>
                    </CustomTooltip>
                )}

                <div
                    onClick={(event: any) =>
                        setAnchorEl(event.currentTarget)
                    }
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingRight: '10px',
                    }}
                >
                  <EllipsisIcon/>
                </div>

                {/*{ArchiveShow && item?.userId === user?._id ?*/}
                {/*    <CustomTooltip title={item?.isArchived ? "Unarchived Ad" : "Archived Ad"} id="ad_card">*/}
                {/*      <ViewDeatilsButton*/}
                {/*          onClick={() => handleArchived && handleArchived(item?._id, item?.isArchived)}*/}
                {/*      >*/}
                {/*        <ArchivedIcon rotate={item?.isArchived ? 180 : 0}/>*/}
                {/*      </ViewDeatilsButton>*/}
                {/*    </CustomTooltip> : ''}*/}

                {/*<CustomTooltip title="Copy link to clipboard" id="ad_card">*/}
                {/*  <ViewDeatilsButton*/}
                {/*      onClick={() => {*/}
                {/*        navigator.clipboard.writeText(*/}
                {/*            `${window.location.origin + window.location.pathname}${*/}
                {/*                isDetailsPage ? "" : `/adDetails/${item._id}`*/}
                {/*            }`*/}
                {/*        );*/}
                {/*        notify({*/}
                {/*          message: "Text copied to clipboard!",*/}
                {/*          type: ToastTypes.SUCCESS,*/}
                {/*        });*/}
                {/*      }}*/}
                {/*  >*/}
                {/*    <AdShareIcon/>*/}
                {/*  </ViewDeatilsButton>*/}
                {/*</CustomTooltip>*/}

                {/*{!adDetailsCard && dataList && (*/}
                {/*    <NavLink*/}
                {/*        to={*/}
                {/*          isBoardAdsData*/}
                {/*              ? `${locationPathname}/swipe-ads/adDetails/${item._id}`*/}
                {/*              : `${locationPathname}/adDetails/${item._id}`*/}
                {/*        }*/}
                {/*    >*/}
                {/*      <CustomTooltip title="View details" id="ad_card">*/}
                {/*        <ViewDeatilsButton>*/}
                {/*          <AdEyeIcon/>*/}
                {/*        </ViewDeatilsButton>*/}
                {/*      </CustomTooltip>*/}
                {/*    </NavLink>*/}
                {/*)}*/}

              </Box>
            </CardHeader>
            {adDetailsCard && (
                <>
                  <CardDescription>
                    <Typography
                        fontSize={"0.875rem"}
                        width={"100%"}
                        sx={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          ...(!seeMoreDescription && {WebkitLineClamp: 2}),
                        }}
                        overflow={"hidden"}
                        color={theme.colors.black2}
                        fontWeight={400}
                    >
                      {item?.description}
                  </Typography>
                  <SeeMoreButton onClick={handleSeeMore}>
                    {item.description &&
                      (seeMoreDescription ? "See Less" : "See More")}
                  </SeeMoreButton>
                </CardDescription>
              </>
            )}
            <CardMediaWrapper>
              {!hideSaveButton && (
                <>
                  <SaveAdButtonWrapper onClick={handleSaveButtonClick}>
                    <SaveAdButton className="save-button">
                      {Array.isArray(item?.board) && item?.board?.length > 0
                        ? "Saved"
                        : "Save"}
                    </SaveAdButton>
                  </SaveAdButtonWrapper>
                  <ImageOverlay className="overlay" />
                </>
              )}
              <Card
                sx={{
                  width: "100%",
                  borderRadius: "0 0 10px 10px",
                  maxHeight: `${adDetailsCard && "65dvh"}`,
                }}
              >
                {item.imageUrls[0] && (
                  <CardImage
                    src={item.imageUrls[0] ?? ImageSkeleton}
                    alt={"ad"}
                    loading="lazy"
                    style={{
                      objectFit:
                        adDetailsCard === true
                          ? "contain"
                          : adDetailsCard === false
                          ? "fill"
                          : undefined,
                    }}
                  />
                )}
                {item?.videoUrls[0] && (
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      position: "relative", cursor: "pointer",
                      height: `${adDetailsCard && "100%"}`
                  }}
                  >
                    <>
                      <video
                        ref={videoRef}
                        height="100%"
                        width="100%"
                        onEndedCapture={() => setStartVideo(false)}
                        key={item.videoUrls[0]}
                        style={{ display: "flex" }}
                        controls
                      >
                        <source src={item.videoUrls[0]} type="video/webm" />
                      </video>
                    </>
                  </div>
                )}
              </Card>
            </CardMediaWrapper>
          </>
        )}

        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              ".MuiMenu-paper": {
                borderRadius: "0.625rem",
              },
              ".MuiList-root": {
                padding: "0",
                display: "flex",
                flexDirection: "column",
              },
            }}
        >
          {/*{showRedirectIcon && item.adTargetSourceURL && ( <MenuItem*/}
          {/*    style={{*/}
          {/*      gap: "0.75rem",*/}
          {/*      padding: "10px",*/}
          {/*      borderBottom: "1px solid #E3E6EA",*/}
          {/*    }}>*/}
          {/*      <CustomTooltip title="Redirect to the Ad" id="ad_card">*/}
          {/*        <RedirectionButton*/}
          {/*            target={"_blank"}*/}
          {/*            href={item.adTargetSourceURL}*/}
          {/*        >*/}
          {/*          <ArrowOutwardIcon fill={theme.colors.black2} />*/}
          {/*        </RedirectionButton>*/}
          {/*      </CustomTooltip>*/}
          {/*</MenuItem>)}*/}

          {ArchiveShow && item?.userId === user?._id ?  <MenuItem
              onClick={() => handleArchived && handleArchived(item?._id, item?.isArchived)}
              style={{
                gap: "0.75rem",
                padding: "10px",
                borderBottom: "1px solid #E3E6EA",
              }}>

                <CustomTooltip title={item?.isArchived ? "Unarchived Ad" : "Archived Ad"} id="ad_card">
                  <div style={{display:'flex',alignItems:'center'}}
                      // onClick={() => handleArchived && handleArchived(item?._id, item?.isArchived)}
                  >
                    <ArchivedIcon rotate={item?.isArchived ? 180 : 0}/>
                    <Typography style={{color:'black',paddingLeft:'10px'}}>{item?.isArchived ? "Unarchived Ad" : "Archived Ad"}</Typography>
                  </div>
                </CustomTooltip>
          </MenuItem>: ''}

          <MenuItem
              onClick={() => {
                navigator.clipboard.writeText(
                    `${window.location.origin}${
                        isDetailsPage ? "" : `/swipe-ads/adDetails/${item._id}`
                    }`
                );
                notify({
                  message: "Text copied to clipboard!",
                  type: ToastTypes.SUCCESS,
                });
              }}
              style={{
                gap: "0.75rem",
                padding: "10px",
                borderBottom: "1px solid #E3E6EA",
              }}>
            <CustomTooltip title="Copy link to clipboard" id="ad_card">
              <div style={{display:'flex',alignItems:'center'}} >
                <AdShareIcon/>
                <Typography style={{color:'black',paddingLeft:'10px'}}>Copy link</Typography>
              </div>
            </CustomTooltip>
            </MenuItem>

          {!adDetailsCard && dataList && (<MenuItem
              style={{
                gap: "0.75rem",
                padding: "10px",
                borderBottom: "1px solid #E3E6EA",
              }}>

                <NavLink
                    style={{textDecoration:'none'}}
                    to={
                      isBoardAdsData
                          ? `${locationPathname}/swipe-ads/adDetails/${item._id}`
                          : `${locationPathname}/adDetails/${item._id}`
                    }
                >
                  <CustomTooltip title="View details" id="ad_card">
                    <div style={{display:'flex',alignItems:'center'}}>
                      <EyeIconMenu />
                      <Typography style={{color:'black',paddingLeft:'10px'}}>View</Typography>
                    </div>
                  </CustomTooltip>
                </NavLink>

          </MenuItem>)}

          {item?.userId === user?._id ? <MenuItem
              onClick={() => handleDelete(item?._id)}
              style={{
                gap: "0.75rem",
                padding: "10px",
                borderBottom: "1px solid #E3E6EA",
              }}>

            <CustomTooltip title='Delete' id="ad_card">
              <div style={{display:'flex',alignItems:'center'}}>
                <DeleteIcon />
                <Typography style={{color:'black',paddingLeft:'10px'}}>Delete</Typography>
              </div>
            </CustomTooltip>
          </MenuItem>: ''}

        </Menu>
      </CardContainer>
    </>
  );
};

export default AdCard;
