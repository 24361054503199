import React, { ReactNode, createContext } from "react";
import { AuthContextType } from "../types/AuthTypes";
import useLocalStorage from "../hooks/useLocalStorage";
import { useLocation } from "react-router-dom";
import routeConstant from "../utils/routeConstant";

const initialState: AuthContextType = {
  isAuthenticated: false,
  setAuthStatus: () => {},
  isPathNameFolder: false,
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext(initialState);

function AuthProvider({ children }: AuthProviderProps) {
  const location = useLocation();
  const pathName = `/${location.pathname.split("/")[1]}`;
  const isPathNameFolder = pathName === routeConstant.myFolders;

  const [auth, setAuth] = useLocalStorage("auth-ts", {
    isAuthenticated: initialState.isAuthenticated,
  });

  const setAuthStatus = (isAuthenticated: boolean) => {
    setAuth({
      ...auth,
      isAuthenticated,
    });
  };

  return (
    <AuthContext.Provider value={{ ...auth, setAuthStatus, isPathNameFolder }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
