import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.archiveState || initialState;

export const archiveFileListSelector = createSelector(
    selectDomain,
    (state) => state.archiveFileListItems
);

export const archivefileFilterOptionSelector = createSelector(
    selectDomain,
    (state) => state.filterOptions
);

export const archiveFileLoadingSelector = createSelector(
    selectDomain,
    (state) => state.isLoading
);

export const archiveFileViewTypeSelector = createSelector(
    selectDomain,
    (state) => state.viewType
);

export const archiveFileSelectedBrandsSelector = createSelector(
    selectDomain,
    (state) => state.filterOptions.selectedPageIds
);

export const archiveFileSelectedPlatformsSelector = createSelector(
    selectDomain,
    (state) => state.filterOptions.selectedPlatformIds
);

export const archiveFilePageSearchSelector = createSelector(
    selectDomain,
    (state) => state.search
);

export const archiveFileFilterCountsSelector = createSelector(
    selectDomain,
    (state) => {
        const {
            selectedPlatformIds,
            selectedPageIds,
            selectedBoardIds,
            selectedTagIds,
        } = state.filterOptions;
        return (
            selectedPlatformIds.length +
            selectedPageIds.length +
            selectedBoardIds.length +
            selectedTagIds.length
        );
    }
);

export const archivefileSelectedSortOptionSelector = createSelector(
    selectDomain,
    (state) => state.filterOptions.sortValue
);

export const archiveFileSelectedTagsSelector = createSelector(
    selectDomain,
    (state) => state.filterOptions.selectedTagIds
);