import { useContext, useEffect, useState } from "react";
import {
  AccountLoginText,
  AccountText,
  BottomButtonWrapper,
  FormFieldWrapper,
  FormWrapper,
  GoogleIconWrapper,
  LoginLayoutContainer,
  LogoWrapper,
  PageTitle,
  StyledLoginButton,
  CredentialSection,
  ForgotPasswordText,
  ErrorMessage,
  ImageSection,
  LoginTextBox,
} from "../style";
import Logo from "../../../assets/images/Logo.png";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import {
  EyeIcon,
  EyeIconButton,
} from "../../../components/commonComponents/formFields/style";
import { GoogleIcon, LoginResponseIcon } from "../../../assets/Icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormValuesProp } from "../../../utils/propTypes";
import { loginValidationSchema } from "../../../utils/formValidationSchema";
import {
  GoogleLoginService,
  LoginService,
} from "../../../services/AuthServices";
import { AuthContext } from "../../../context/AuthContext";
import { CarouselContext } from "../../../context/CarouselContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios, { AxiosResponse } from "axios";
import {
  GoogleLoginResponse,
  LoginSignupResponse,
} from "../../../types/AuthTypes";
import { useDispatch } from "react-redux";
import { AccountReducerAction } from "../../../store/account/slice";
import { ToastTypes, notify, webkitInputCSSFix } from "../../../utils/helpers";
import routeConstant from "../../../utils/routeConstant";
import { useTheme } from "styled-components";
import { CarouselType } from "../../../types/Carousel";
const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const dispatch = useDispatch();

  const { setAuthStatus, isAuthenticated } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [googleLoginLoading, setGoogleLoginLoading] = useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<null | string>(
    null
  );
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<LoginFormValuesProp>({
    resolver: yupResolver(loginValidationSchema),
  });
  const { carouselImages } = useContext(CarouselContext);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async () => {
    setLoginErrorMessage(null);
    const values = getValues();
    values.email = values.email.toLowerCase();
    setLoading(true);
    const response = (await LoginService(
      values
    )) as AxiosResponse<LoginSignupResponse>;
    if (response.status === 200) {
      const data = { ...response?.data?.data, isLoggedIn: true };
      const { token, ...user } = data;
      setAuthStatus(true);
      dispatch(AccountReducerAction.setUserDetails(user.user));
      dispatch(
        AccountReducerAction.setUserPhoneNumber(
          data?.user?.phoneNumber ? data?.user?.phoneNumber : ""
        )
      );
      localStorage.setItem("User", JSON.stringify(user));
      localStorage.setItem("AccessToken", token);
      setLoading(false);
      reset();
      navigate(from, { replace: true });
    } else {
      setLoading(false);
      if (axios.isAxiosError(response)) {
        if (response?.response?.status === 422) {
          notify({
            message: response?.response?.data?.message,
            type: ToastTypes.ERROR,
          });
          navigate(`/${routeConstant.verifyEmail}`, { replace: true });
        }
        setLoginErrorMessage(response.response?.data?.message);
        setLoading(false);
      }
    }
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoginErrorMessage(null);
      setGoogleLoginLoading(true);
      const reqParams = {
        code: tokenResponse.access_token,
      };
      const response = (await GoogleLoginService(
        reqParams
      )) as AxiosResponse<GoogleLoginResponse>;
      if (response?.status) {
        const data = { ...response?.data?.data, isLoggedIn: true };
        setAuthStatus(true);
        localStorage.setItem("User", JSON.stringify(data));
        dispatch(AccountReducerAction.setUserDetails(data.user));
        dispatch(
          AccountReducerAction.setUserPhoneNumber(
            data?.user?.phoneNumber ? data?.user?.phoneNumber : ""
          )
        );
        localStorage.setItem("AccessToken", data.token);
        setGoogleLoginLoading(false);
        navigate(from, { replace: true });
      }
      if (axios.isAxiosError(response)) {
        setLoginErrorMessage(response.response?.data?.message);
        setGoogleLoginLoading(false);
      }
    },
  });

  const handleRedirection = () => {
    navigate(`/${routeConstant.forgotPassword}`);
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    submitHandler: () => void
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitHandler();
    }
  };

  const renderCarousel = (images: CarouselType[], direction: "ltr" | "rtl") => (
    <div className={`Marquee-content Marquee-content-${direction}`}>
      {[...Array(6)].flatMap((_, repetitionIndex) =>
        images.map((image, index) => (
          <div className="Marquee-tag" key={`${index}-${repetitionIndex}`}>
            <img src={image.imageUrl} alt="Vertical-Card-1" />
          </div>
        ))
      )}
    </div>
  );
  return (
    <LoginLayoutContainer container>
      <CredentialSection xs={12} md={6}>
        <LogoWrapper
          onClick={() => navigate("/landing-page")}
          src={Logo}
          alt="app-logo"
        />
        <PageTitle>Log In</PageTitle>
        <AccountText>
          {"Don't have an account ? "}
          <AccountLoginText to="/signup">Sign Up</AccountLoginText>
        </AccountText>
        {loginErrorMessage && (
          <LoginTextBox style={{ background: theme.colors.error }}>
            <LoginResponseIcon />
            {loginErrorMessage}
          </LoginTextBox>
        )}

        <FormWrapper>
          <FormFieldWrapper>
            <FormControl
              fullWidth
              sx={{
                "& > label": {
                  top: 23,
                  left: 0,
                  color: "white",
                  '&[data-shrink="false"]': {
                    top: 5,
                  },
                },
                "& > div > input": {
                  padding: "1.906rem 0.875rem 0.719rem  !important",
                  color: "white",
                  border: "3px solid white",
                  borderRadius: "2px",
                  zIndex: 2,
                },
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            >
              <InputLabel
                style={{ color: "white" }}
                htmlFor="outlined-adornment-email-login"
              >
                Email
              </InputLabel>
              <OutlinedInput
                {...register("email", { required: true })}
                id="outlined-adornment-email-login"
                type="email"
                onKeyDown={(e) => onKeyDown(e, handleSubmit(onSubmit))}
                sx={webkitInputCSSFix}
              />
            </FormControl>
            {errors.email?.message && (
              <ErrorMessage>{errors.email?.message}</ErrorMessage>
            )}

            <FormControl
              fullWidth
              sx={{
                "& > label": {
                  top: 23,
                  left: 0,
                  color: "white",
                  '&[data-shrink="false"]': {
                    top: 5,
                  },
                },
                "& > div > input": {
                  padding: "1.906rem 0.875rem 0.719rem !important",
                  color: "white",

                  zIndex: 2,
                },
                "& > div": {
                  border: "3px solid white",
                  borderRadius: "2px",
                },
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            >
              <InputLabel
                style={{
                  width: "100%",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                htmlFor="outlined-adornment-password-login"
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                label="Password"
                sx={webkitInputCSSFix}
                endAdornment={
                  <EyeIconButton
                    onClick={(e) => {
                      e.preventDefault();
                      handlePassword();
                    }}
                  >
                    <EyeIcon
                      src={
                        showPassword
                          ? "/assets/images/eye-icon.svg"
                          : "/assets/images/eye-close.svg"
                      }
                      alt="Show-Hide-Password-Icon"
                    />
                  </EyeIconButton>
                }
                onKeyDown={(e) => onKeyDown(e, handleSubmit(onSubmit))}
                {...register("password", { required: true })}
              />
            </FormControl>
            {errors.password?.message && (
              <ErrorMessage>{errors.password?.message}</ErrorMessage>
            )}
          </FormFieldWrapper>
          <ForgotPasswordText onClick={handleRedirection}>
            Forgot Password ?
          </ForgotPasswordText>
          <BottomButtonWrapper>
            <StyledLoginButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              startIcon={
                loading && <CircularProgress size={20} color="inherit" />
              }
            >
              Login
            </StyledLoginButton>
            <StyledLoginButton
              variant="contained"
              withGoogle
              onClick={() => loginWithGoogle()}
              disabled={googleLoginLoading}
            >
              <GoogleIconWrapper>
                {googleLoginLoading ? (
                  <CircularProgress size={22} color="inherit" />
                ) : (
                  <GoogleIcon />
                )}
              </GoogleIconWrapper>
              Login with Google
            </StyledLoginButton>
          </BottomButtonWrapper>
        </FormWrapper>
      </CredentialSection>
      <ImageSection xs={12} md={6}>
        {carouselImages.carousel_one?.length > 0 && (
          <>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_one, "ltr")}
            </div>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_two, "rtl")}
            </div>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_three, "ltr")}
            </div>
          </>
        )}
      </ImageSection>
    </LoginLayoutContainer>
  );
};

export default Login;
