import {
  PanelItem,
  PanelLogo,
  SidebarWrapper,
  PanelLogoWrapper,
  BlurredOverlay,
} from "../../pageComponents/auth/style";
import {
  DashboardIcon,
  DiscoveryIcon,
  LogoSmall,
  SwipeFileIcon,
} from "../../assets/Icons";
import { Box, Button, Typography } from "@mui/material";
import Logo from "../../assets/images/Logo.png";
import useConfig from "../../hooks/useConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import SidebarFolder from "../sidebarFolder";
import routeConstant from "../../utils/routeConstant";
import AddUserModal from "../addUserModal";
import SidebarSharedFolders from "../sidebarSharedFolder";
import { userDetailsSelector } from "../../store/account/selector";
import useAuth from "../../hooks/useAuth";
import { MetaDataReducerAction } from "../../store/metadata/slice";

const drawerData = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    link: routeConstant.dashboard,
  },
  {
    name: "Swiped Ads",
    icon: <SwipeFileIcon />,
    link: routeConstant.swipeFiles,
  },
  {
    name: "Explore Lounge",
    icon: <DiscoveryIcon />,
    link: routeConstant.discovery,
  },
];
const Sidebar = () => {
  const { drawerOpen } = useConfig();
  const { pathname } = useLocation();
  const { folderId } = useParams();
  const navigate = useNavigate();
  const userDetails = useSelector(userDetailsSelector);
  const CLICKED_URL = "/" + pathname.split("/")[1];
  const dispatch = useDispatch();
  const { isAuthenticated, isPathNameFolder } = useAuth();

  useEffect(() => {
    userDetails?.planId &&
      dispatch(MetaDataSagaActions.fetchSharedFolderList());
  }, [userDetails]);

  useEffect(() => {
    userDetails?.planId &&
      dispatch(
        MetaDataSagaActions.fetchFolderList({
          selectedFolderId: folderId,
          selectedBoardId: null,
        })
      );
  }, [userDetails]);

  const handleBlurSectionClick = () => {
    if (!isAuthenticated) {
      dispatch(MetaDataReducerAction.setOpenRegisterNowModal(true));
    }
  };
  return (
    <SidebarWrapper id="side-panel" drawerOpen={drawerOpen}>
      <Button sx={{ m: 0, p: 0 }} onClick={() => navigate("/")}>
        {drawerOpen ? (
          <PanelLogo src={Logo} alt="brand-logo" />
        ) : (
          <PanelLogoWrapper>
            <LogoSmall />
          </PanelLogoWrapper>
        )}
      </Button>

      <Box
        sx={{ position: "relative", display: "flex", flexDirection: "column" }}
      >
        {!isAuthenticated && <BlurredOverlay onClick={handleBlurSectionClick} />}
        {drawerData.map((item, index: number) => (
          <PanelItem
            key={index}
            to={item?.link}
            drawerOpen={drawerOpen}
            style={{
              background:
                item?.link === CLICKED_URL
                  ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                  : "transparent",
            }}
          >
            {item?.icon}
            {item?.name && drawerOpen && (
              <Typography style={{ marginLeft: "0.857rem" }}>
                {item?.name}
              </Typography>
            )}
          </PanelItem>
        ))}
      </Box>

      {drawerOpen && (
        <>
          <SidebarFolder />
          <Box sx={{ position: "relative" }}>
            {!isAuthenticated && <BlurredOverlay onClick={handleBlurSectionClick} />}
            <SidebarSharedFolders />
          </Box>
        </>
      )}

      <AddUserModal />
    </SidebarWrapper>
  );
};

export default Sidebar;
