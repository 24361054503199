import { Button, Link } from "@mui/material";
import styled from "styled-components";
import { CustomButton } from "../../../globalStyle";

export const CardHeader = styled.div`
  display: flex;
  padding: 3% 2% 3% 3%;
  justify-content: space-between;
  align-items: center;
  gap: 0.375rem;
`;

export const CardContainer = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.border1}`};
  border-radius: 10px;
  background-color: white;
  &:hover .save-button,
  &:hover .overlay {
    display: block;
  }
`;

export const SaveAdButton = styled(Button)`
  &.MuiButton-root {
    background-color: white !important;
    color: ${({ theme }) => theme.colors.black3} !important;
    text-transform: capitalize;
    font-size: 0.938rem;
    font-weight: 600;
    border-radius: 22px;
    padding: 0.625rem 1.375rem;
    z-index: 1;
    display: none;
  }
`;

export const SaveAdButtonWrapper = styled.div`
  height: 2.875rem;
  width: 5.063rem;
  position: absolute;
  top: 0.938rem;
  right: 0.938rem;
`;

export const CardMediaWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const CardImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000066;
  border-radius: 0 0 10px 10px;
  display: none;
`;

export const CardDetailsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  width: 50%;
  align-items: center;
`;

export const CardDescription = styled.div`
  padding: 0 3% 3%;
  align-items: center;
`;

export const VideoPlayButton = styled(CustomButton)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    color: white;
    border-radius: 50%;
    background: #00000066 !important;
    padding: 0.25rem;
    margin: 0;
  }
`;

export const VideoMuteButton = styled(CustomButton)`
  && {
    position: absolute;
    bottom: 0.25rem;
    right: 0;
    z-index: 1;
    color: white;
    border-radius: 50%;
  }
`;

export const SeeMoreButton = styled(Button)`
  && {
    padding: 0;
    background: none !important;
    text-transform: capitalize;
    text-decoration: underline !important;
    color: ${({ theme }) => theme.colors.link};
    display: inline-block;
  }
`;

export const ViewDeatilsButton = styled(CustomButton)`
  && {
    padding: 0.2rem 0.5rem;
    width: fit-content;
    background: none !important;
    border-radius: 20%;
    &:hover {
      background-color: #0003 !important;
    }
  }
`;

export const ViewDeatilsButtonWithouthover = styled(CustomButton)`
  && {
    padding: 0.2rem 0.5rem;
    width: fit-content;
    background: none !important;
    border-radius: 20%;
   
  }
`;
export const RedirectionButton = styled(Link)`
  && {
    padding: 0.2rem 0.5rem;
    width: fit-content;
    background: none !important;
    border-radius: 20%;
    &:hover {
      background-color: #0003 !important;
    }
  }
`;
