import { Outlet } from "react-router";
import Sidebar from "../sidebar";
import { Box } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import useConfig from "../../hooks/useConfig";
import { MainLayoutWrapper } from "../../pageComponents/auth/style";

const MainLayoutPublicFolderRoute = () => {
  const { isPathNameFolder } = useAuth();

  const { drawerOpen } = useConfig();

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {isPathNameFolder && <Sidebar />}
      <MainLayoutWrapper drawerOpen={drawerOpen}>
        <Outlet />
      </MainLayoutWrapper>
    </Box>
  );
};

export default MainLayoutPublicFolderRoute;
