import React, {KeyboardEventHandler, ReactNode, useEffect, useMemo} from 'react';
import {Container, HeaderSection, HeaderSubTitle, HeaderTitle, ListContainer, Tablecontainer} from "../style";
import Header from "../../components/header";
import {listType} from "../../store/metadata/types";
import {useDispatch, useSelector} from "react-redux";
import SearchAndFilter from "../../components/searchAndFilter";
import {SwipeFileListItems, ViewTypeEnum} from "../../store/swipeFile/types";
import CardListWrapper from "../../components/commonComponents/adCard/cardListWrapper";
import AdCard from "../../components/commonComponents/adCard";
import InfiniteScroll from "react-infinite-scroll-component";
import {Box, CircularProgress} from "@mui/material";
import AdTable from "../../components/commonComponents/adTable";
import {ArchivedSagaActions} from "../../store/archive/sagas";
import {MetaDataReducerAction} from "../../store/metadata/slice";
import {
    archiveFileFilterCountsSelector,
    archiveFileListSelector,
    archiveFileLoadingSelector,
    archiveFilePageSearchSelector,
    archiveFileSelectedBrandsSelector,
    archiveFileSelectedPlatformsSelector, archivefileSelectedSortOptionSelector, archiveFileSelectedTagsSelector,
    archiveFileViewTypeSelector
} from "../../store/archive/selector";
import {DiscoverySagaActions} from "../../store/discovery/sagas";
import {useLocation} from "react-router-dom";
import {ArchiveReducerAction} from "../../store/archive/slice";
import {archiveFilesTabs} from "../../utils/constants";


const ArchiveFiles = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const archiveFileListItems = useSelector(archiveFileListSelector);
    const isLoading = useSelector(archiveFileLoadingSelector);
    const viewType = useSelector(archiveFileViewTypeSelector);
    const selectedBrands = useSelector(archiveFileSelectedBrandsSelector);
    const selectedPlatforms = useSelector(archiveFileSelectedPlatformsSelector);
    const searchValue = useSelector(archiveFilePageSearchSelector);
    const swipeFileFilterCounts = useSelector(archiveFileFilterCountsSelector);
    const selectedTags = useSelector(archiveFileSelectedTagsSelector);

    const userDetails = JSON.parse(localStorage.getItem("User") as string);
    const content = {
        tabTitle: "Archived Ads",
        tabSubTitle: `Browse ${archiveFileListItems?.total !== undefined ? archiveFileListItems?.total : '0' } ads Archived by users`,
    };

    const fetchfilterArchiveFileItems = (
        selectAll: boolean,
        checked: boolean,
        stateName: string,
        list: listType[],
        id?: string
    ) => {
        dispatch(
            ArchivedSagaActions.applyFilterOnArchivedFileList({
                selectAll,
                checked,
                id,
                list,
                stateName,
            })
        );
    };

    const fetchSortingArchiveFileItems = (
        sortValue: string,
        sortObject: Object
    ) => {
        dispatch(
            ArchiveReducerAction.setSelectedSortOption({ sortValue, sortObject })
        );
        dispatch(ArchivedSagaActions.fetchSortedSwipeFileList());
    };

    const ArchivefileSelectedSortOption = useSelector(
        archivefileSelectedSortOptionSelector
    );

    const handleSearchChange = (value: string) => {
        dispatch(ArchiveReducerAction.setArchiveFilePageSearchInputValue(value));
    };

    const hanldeSearchButton = () => {

            dispatch(ArchivedSagaActions.fetchSearchedArchiveFileItema());

    };

    const handleSearchText: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === "Enter") {
            hanldeSearchButton();
        }
    };

    const handleClearArchiveFileFilter = () => {
        dispatch(ArchiveReducerAction.resetarchivefileState());
        dispatch(
            ArchivedSagaActions.fetchArchiveFileList({
                userId: userDetails?.user?._id,
            })
        );
    };

    const handleViewBrandAds = (brandId: string) => {
        dispatch(
            ArchivedSagaActions.fetchSelectedBrandSwipeFileItems({
                brandId,
            })
        );
    };

    const handleArchive = (pageId: string,isArchived:boolean) => {
        dispatch(
            DiscoverySagaActions.fetchArchiveAds({
                pageId,
                isArchived: !isArchived,
            })
        );
    };

    const archiveMoreSwipeFileItem = () => {
        dispatch(ArchivedSagaActions.fetchArchiveFileList(
            {userId: userDetails?.user?._id,}
        ));
    };
    useEffect(() => {

            dispatch(ArchivedSagaActions.fetchArchiveFileList(
                {userId: userDetails?.user?._id,}
            ));
        return () => {
            dispatch(ArchiveReducerAction.resetarchivefileState());
            dispatch(MetaDataReducerAction.setBrandFilterSearchText(""));
        };
    }, [dispatch]);

    const RenderAdsComponent = useMemo(() => {
        const swipeFileListItemsLength = archiveFileListItems?.data?.length ?? 0;
        const hasMoreData = archiveFileListItems?.total > swipeFileListItemsLength;

        if (!isLoading && swipeFileListItemsLength === 0) {
            return (
                <ListContainer>
                    <HeaderTitle textAlign="center">
                        Your archived Ads will appear here
                    </HeaderTitle>
                    {/*<HeaderSubTitle textAlign="center">*/}
                    {/*    Use our extension to save ads from platforms and grow*/}
                    {/*</HeaderSubTitle>*/}
                </ListContainer>
            );
        }
        return (
            <>

                {viewType === ViewTypeEnum.Card && (
                    <ListContainer>
                        <CardListWrapper>
                            {archiveFileListItems?.data?.map(
                                (item: SwipeFileListItems, index: number) => (
                                    <AdCard
                                        item={item}
                                        key={item?._id}
                                        index={index}
                                        dataList={archiveFileListItems?.data}
                                        handleViewAd={handleViewBrandAds}
                                        locationPathname={location.pathname}
                                        handleArchived = {handleArchive}
                                        ArchiveShow={true}
                                    />
                                )
                            )}
                            {(isLoading || hasMoreData) &&
                                [...Array(4)]?.map((index) => (
                                    <AdCard item={{} as SwipeFileListItems} key={index} />
                                ))}
                        </CardListWrapper>
                        <InfiniteScroll
                            scrollableTarget={document.getElementById('content') as ReactNode}
                            dataLength={swipeFileListItemsLength ?? 0}
                            next={archiveMoreSwipeFileItem}
                            hasMore={hasMoreData}
                            loader={
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <CircularProgress />
                                </Box>
                            }
                            style={{ overflow: "hidden" }}
                            key={swipeFileListItemsLength}
                        >
                            <></>
                        </InfiniteScroll>
                    </ListContainer>
                )}

                {viewType === ViewTypeEnum.List && (
                    <Tablecontainer id="scrollableDiv">
                        <InfiniteScroll
                            scrollableTarget={document.getElementById('content') as ReactNode}
                            dataLength={swipeFileListItemsLength}
                            next={archiveMoreSwipeFileItem}
                            hasMore={hasMoreData}
                            loader={
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <CircularProgress />
                                </Box>
                            }
                            style={{ overflow: "unset" }}
                            key={swipeFileListItemsLength}
                            // scrollableTarget="scrollableDiv"
                        >
                            <AdTable />
                        </InfiniteScroll>
                    </Tablecontainer>
                )}
            </>
        );
    }, [
        archiveFileListItems?.data,
        archiveFileListItems?.total,
        viewType,
        isLoading,
    ]);
    return(
        <Container>
            <HeaderSection>
                <Header content={content} />
                <SearchAndFilter
                    handleFilter={fetchfilterArchiveFileItems}
                    selectedBrands={selectedBrands}
                        selectedPlatforms={selectedPlatforms}
                    handleSortFilter={fetchSortingArchiveFileItems}
                    selectedFilterOption={ArchivefileSelectedSortOption}
                    searchValue={searchValue}
                    handleSearchChange={handleSearchChange}
                    hanldeSearchButton={hanldeSearchButton}
                    buttonsToShow={
                        archiveFilesTabs
                    }
                    renderTagFilter={true}
                    filterCounts={swipeFileFilterCounts}
                    userId={userDetails?.user?._id}
                    handleClearFilter={handleClearArchiveFileFilter}
                    handleKeyDown={handleSearchText}
                    selectedTags={selectedTags}
                />
                {RenderAdsComponent}

            </HeaderSection>
        </Container>
    )
};

export default ArchiveFiles;