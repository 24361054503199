import { call, put, select } from "redux-saga/effects";
import { createSliceSaga } from "../saga-helper";
import { DiscoveryReducerAction } from "./slice";
import { AxiosResponse } from "axios";
import { getDiscoveryAdsList } from "../../services/DiscoveryServices";
import {
  filterOptionSelector,
  discoveryBrandsParamsSelector,
  discoveryPageSearchSelector,
} from "./selector";
import { FilterOptions, discoveryBrandsParamsType } from "./types";
import { TopBrandLists } from "../../services/BrandsService";
import * as Sentry from "@sentry/react";
import { getCommunityAdsList } from "../../services/CommunityService";
import {ArchivedAddRemove} from "../../services/BoardServices";
import { ToastTypes, notify } from "../../utils/helpers";
import {SwipeFileReducerAction} from "../swipeFile/slice";
import {ArchiveReducerAction} from "../archive/slice";

const saga = createSliceSaga({
  name: "discovery-state",
  caseSagas: {
    *fetchDiscoveryList() {
      try {
        const discoveryFilterOption: FilterOptions = yield select(
          filterOptionSelector
        );
        yield put(DiscoveryReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(
          getDiscoveryAdsList,
          discoveryFilterOption
        );
        if (response?.status === 200) {
          yield put(DiscoveryReducerAction.setDiscoveryItems(response?.data));
          yield put(
            DiscoveryReducerAction.setFilterOptionSkipValue(
              discoveryFilterOption.skip + discoveryFilterOption.limit
            )
          );
          yield put(DiscoveryReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(DiscoveryReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *applyFilterOnDiscoveryList(action: any) {
      try {
        yield put(DiscoveryReducerAction.setFilterOptionSkipValue(0));
        yield put(DiscoveryReducerAction.setDiscoveryItemToInitialSet([]));
        yield put(
          DiscoveryReducerAction.setSelectedFilterOptions(action.payload)
        );
        const data: FilterOptions = yield select(filterOptionSelector);

        yield put(DiscoveryReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(getDiscoveryAdsList, data);

        if (response?.status === 200) {
          yield put(DiscoveryReducerAction.setDiscoveryItems(response?.data));
          yield put(
            DiscoveryReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(DiscoveryReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(DiscoveryReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchDiscoveryTopBrandList(action: {
      payload: {
        skip: number;
        limit: number;
        userId: string;
        search: string;
        favourite: boolean;
      };
      type: string;
    }) {
      try {
        if (action.payload.skip === 0) {
          yield put(DiscoveryReducerAction.setIsLoading(true));
        }
        const response: AxiosResponse = yield call(
          TopBrandLists,
          action.payload
        );
        if (response?.status === 200) {
          yield put(
            DiscoveryReducerAction.setDiscoveryTopBrands({
              brandData: response?.data,
              skipValue: action.payload.skip,
            })
          );
          yield put(
            DiscoveryReducerAction.setDiscoveryBrandsParams(action.payload.skip)
          );
        }
        yield put(DiscoveryReducerAction.setIsLoading(false));
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchBrandDiscoveryItems(action: {
      payload: {
        brandId: string;
        handleLoadingState: Function;
      };
      type: string;
    }) {
      try {
        yield put(DiscoveryReducerAction.setIsLoading(true));
        yield put(DiscoveryReducerAction.setBrandsLoading(true));
        yield put(
          DiscoveryReducerAction.setFilterOptionBrandId(action.payload.brandId)
        );
        yield put(DiscoveryReducerAction.setFilterOptionSkipValue(0));
        yield put(DiscoveryReducerAction.setDiscoveryItemToInitialSet([]));
        const data: FilterOptions = yield select(filterOptionSelector);

        const response: AxiosResponse = yield call(getDiscoveryAdsList, data);
        if (response?.status === 200) {
          yield put(DiscoveryReducerAction.setDiscoveryItems(response?.data));
          yield put(
            DiscoveryReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(DiscoveryReducerAction.setDiscoveryTabIndex(0));
          yield put(DiscoveryReducerAction.setBrandsLoading(false));
          yield put(DiscoveryReducerAction.setIsLoading(false));
          action.payload.handleLoadingState &&
            action.payload.handleLoadingState();
          window.scrollTo(0, 0);
        }
      } catch (error) {
        yield put(DiscoveryReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSearchedDiscoveryItem() {
      try {
        yield put(DiscoveryReducerAction.setFilterOptionSkipValue(0));
        yield put(DiscoveryReducerAction.setDiscoveryItemToInitialSet([]));
        const search: string = yield select(discoveryPageSearchSelector);
        const data: FilterOptions = yield select(filterOptionSelector);
        yield put(DiscoveryReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(getDiscoveryAdsList, {
          ...data,
          search: search,
        });

        if (response?.status === 200) {
          yield put(DiscoveryReducerAction.setDiscoveryItems(response?.data));
          yield put(
            DiscoveryReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(DiscoveryReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(DiscoveryReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSearchedCommunityItem() {
      try {
        yield put(DiscoveryReducerAction.setFilterOptionSkipValue(0));
        yield put(DiscoveryReducerAction.setCommunityItemToInitialSet([]));
        const search: string = yield select(discoveryPageSearchSelector);
        const data: FilterOptions = yield select(filterOptionSelector);
        yield put(DiscoveryReducerAction.setIsLoading(true));
        const userDetails = JSON.parse(localStorage.getItem("User") as string);
        const response: AxiosResponse = yield call(getDiscoveryAdsList, {
          ...data,
          search: search,
          userId: userDetails?.user?._id,
        });

        if (response?.status === 200) {
          yield put(DiscoveryReducerAction.setCommunityItems(response?.data));
          yield put(
              DiscoveryReducerAction.setFilterOptionSkipValue(
                  data.skip + data.limit
              )
          );
          yield put(DiscoveryReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(DiscoveryReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchCommunityList() {
      try {

        const data: FilterOptions = yield select(filterOptionSelector);
        yield put(DiscoveryReducerAction.setIsLoading(true));

        const userDetails = JSON.parse(localStorage.getItem("User") as string);
        const response: AxiosResponse = yield call(getCommunityAdsList, {
          ...data,
          userId: userDetails?.user?._id,
        });
        if (response?.status === 200) {
          yield put(DiscoveryReducerAction.setCommunityItems(response?.data));
          yield put(
            DiscoveryReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(DiscoveryReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(DiscoveryReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSortedDiscoveryList() {
      try {
        yield put(DiscoveryReducerAction.setFilterOptionSkipValue(0));
        yield put(DiscoveryReducerAction.setDiscoveryItemToInitialSet([]));
        const data: FilterOptions = yield select(filterOptionSelector);

        yield put(DiscoveryReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(getDiscoveryAdsList, data);

        if (response?.status === 200) {
          yield put(DiscoveryReducerAction.setDiscoveryItems(response?.data));
          yield put(
            DiscoveryReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(DiscoveryReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(DiscoveryReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSearchTopBrandList(action: {
      payload: {
        handleScroll: Function;
      };
      type: string;
    }) {
      try {
        yield put(DiscoveryReducerAction.setDiscoveryBrandsParams(0));
        yield put(
          DiscoveryReducerAction.setDiscoveryTopBrandsToInitialState([])
        );
        const search: string = yield select(discoveryPageSearchSelector);
        const discoveryBrandsParams: discoveryBrandsParamsType = yield select(
          discoveryBrandsParamsSelector
        );
        const response: AxiosResponse = yield call(TopBrandLists, {
          ...discoveryBrandsParams,
          search: search,
        });
        if (response?.status === 200) {
          yield put(
            DiscoveryReducerAction.setDiscoveryTopBrands({
              brandData: response?.data,
              skip: 0,
            })
          );
          yield put(
            DiscoveryReducerAction.setDiscoveryBrandsParams(
              discoveryBrandsParams.skip + discoveryBrandsParams.limit
            )
          );
          if (action?.payload?.handleScroll) {
            action.payload.handleScroll();
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },

    *fetchSelectedBrandDiscoveryItems(action: {
      payload: {
        brandId: string;
      };
      type: string;
    }) {
      try {
        yield put(DiscoveryReducerAction.setIsLoading(true));
        yield put(
          DiscoveryReducerAction.setFilterOptionBrandId(action.payload.brandId)
        );
        yield put(DiscoveryReducerAction.setFilterOptionSkipValue(0));
        yield put(DiscoveryReducerAction.setDiscoveryItemToInitialSet([]));
        const data: FilterOptions = yield select(filterOptionSelector);

        const response: AxiosResponse = yield call(getDiscoveryAdsList, data);
        if (response?.status === 200) {
          yield put(DiscoveryReducerAction.setDiscoveryItems(response?.data));
          yield put(
            DiscoveryReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(DiscoveryReducerAction.setDiscoveryTabIndex(0));
          yield put(DiscoveryReducerAction.setIsLoading(false));

          window.scrollTo(0, 0);
        }
      } catch (error) {
        yield put(DiscoveryReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },

    *fetchArchiveAds(action: {
      payload: {
        pageId: string;
        isArchived:boolean
      };
      type: string;
    }) {
      try {
        const { pageId, isArchived } = action.payload;
        const response: AxiosResponse = yield call(
            ArchivedAddRemove,
            pageId,
            isArchived
        );
        if (response?.status === 200) {
          yield put(SwipeFileReducerAction.setUpdateSwipeFileItem(pageId));
          yield put(DiscoveryReducerAction.setDiscoveryItemDataById(pageId));
          yield put(ArchiveReducerAction.setUpdateArchiveFileItem(pageId));
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        }

      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: DiscoverySagaActions,
  saga: DiscoverySaga,
  name: DiscoverySagaName,
} = saga;
