import { Box } from "@mui/material";
import {
  CloseIconWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
} from "./style";
import { CloseIcon, LogoSmall } from "../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import { openRegisterNowModalSelector } from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { useNavigate } from "react-router-dom";
import routeConstant from "../../utils/routeConstant";

const RegisterNowModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = useSelector(openRegisterNowModalSelector);
  const handleClose = () => {
    dispatch(MetaDataReducerAction.setOpenRegisterNowModal(false));
  };

  return (
    <StyledDialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <CloseIconWrapper aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </CloseIconWrapper>

      <Box
        sx={{
          margin: "57px 0 30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "68px",
            width: "68px",
            backgroundColor: "#151616",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8.89px",
            marginBottom: "26px",
          }}
        >
          <LogoSmall />
        </Box>
        <ModalTitle>Please Create an Account to Access This.</ModalTitle>
        <StyledContainedButton
          variant="contained"
          size="small"
          sx={{
            marginTop: "46px",
          }}
          onClick={() => navigate(`/${routeConstant.signup}`)}
        >
          Register Now
        </StyledContainedButton>
      </Box>
    </StyledDialog>
  );
};

export default RegisterNowModal;
