import {ReactNode, useEffect, useMemo} from "react";
import {
  Container,
  HeaderSection,
  HeaderSubTitle,
  HeaderTitle,
  ListContainer,
} from "../style";
import { useDispatch, useSelector } from "react-redux";
import { FilterOptions, SwipeFileListItems } from "../../store/swipeFile/types";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import {
  Box,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  listType,
  selectFolderBoardItem,
} from "../../store/metadata/types";
import CardListWrapper from "../../components/commonComponents/adCard/cardListWrapper";
import InfiniteScroll from "react-infinite-scroll-component";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { useParams } from "react-router-dom";
import {
  boardAdsFilterCountSelector,
  boardAdsItemsSelector,
  boardAdsSearchSelector,
  filterOptionSelector,
  isLoadingSelector,
  openRegisterNowModalSelector,
  selectedBrandsSelector,
  selectedPlatformsSelector,
  selectedTabIndexSelector,
  selectedTagsSelector,
  selectFolderDetailsSelector,
} from "../../store/metadata/selector";
import { FolderIconDark } from "../../assets/Icons";
import { theme } from "../../utils/theme";
import HeaderFolderPublicRoute from "../../components/header/headerFolderPublicRoute";
import SearchAndFilterFolderPublicRoute from "../../components/searchAndFilter/searchAndFilterFolderPublicRoute";
import PublicAdCard from "../../components/commonComponents/adCard/publicAdCard";
import RegisterNowModal from "../../components/modals/registerNowModal";

const PublicMyFolder = () => {
  const dispatch = useDispatch();

  const { folderId } = useParams();
  const boardAdsItems = useSelector(boardAdsItemsSelector);
  const userDetails = JSON.parse(localStorage.getItem("User") as string);
  const selectedBrands = useSelector(selectedBrandsSelector);
  const selectedTabIndex = useSelector(selectedTabIndexSelector);
  const selectedPlatforms = useSelector(selectedPlatformsSelector);
  const selectedTags = useSelector(selectedTagsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const filterOption = useSelector(filterOptionSelector);
  const boardAdsFilterCount = useSelector(boardAdsFilterCountSelector);
  const selectedFolderDetails = useSelector(selectFolderDetailsSelector);
  const searchValue = useSelector(boardAdsSearchSelector);
  const openRegisterNowModal = useSelector(openRegisterNowModalSelector);

  const content = {
    tabTitle: isLoading ? "" : selectedFolderDetails?.name,
    tabSubTitle: "",
  };

  const setSwipeFileTabIndex = (tabIndex: number) => {
    dispatch(MetaDataReducerAction.setSelectedTabIndex(tabIndex));
    dispatch(MetaDataReducerAction.resetSwipefileState());
    fetchFolderBoardAds(tabIndex);
  };

  const handleSearchChange = async (value: string) => {
    if (!value) {
      await dispatch(MetaDataReducerAction.resetSkipLimitValue());
    }
    dispatch(MetaDataReducerAction.setBoardAdsSearchValue(value));
    dispatch(
      MetaDataSagaActions.fetchFolderAdsList({
        selectedFolderId: folderId,
        selectedTabIndex,
        type: "filter",
      })
    );
  };

  const fetchSortingSwipeFileItems = (
    sortValue: string,
    sortObject: Object
  ) => {
    dispatch(
      MetaDataReducerAction.setSelectedSortOption({ sortValue, sortObject })
    );
    dispatch(
      MetaDataSagaActions.fetchFolderAdsList({
        selectedFolderId: folderId,
        selectedTabIndex,
        type: "filter",
      })
    );
  };

  const fetchfilterSwipeFileItems = (
    selectAll: boolean,
    checked: boolean,
    stateName: keyof FilterOptions,
    list: listType[],
    id?: string
  ) => {
    dispatch(
      MetaDataReducerAction.setSelectedFilterOptions({
        selectAll,
        checked,
        id,
        list,
        stateName,
      })
    );
    dispatch(
      MetaDataSagaActions.fetchFolderAdsList({
        selectedFolderId: folderId,
        selectedTabIndex,
        type: "filter",
      })
    );
  };

  const handleClearSwipeFileFilter = () => {
    dispatch(MetaDataReducerAction.resetSwipefileState());

    dispatch(
      MetaDataSagaActions.fetchFolderAdsList({
        selectedFolderId: folderId,
        selectedTabIndex: 0,
      })
    );
  };

  const fetchFolderBoardAds = (tabIndex?: number) => {
    if (selectedFolderDetails) {
      dispatch(
        MetaDataSagaActions.fetchFolderAdsList({
          selectedFolderId: folderId,
          selectedTabIndex,
        })
      );
    }
  };

  useEffect(() => {
    fetchFolderBoardAds();
  }, [selectedFolderDetails]);

  useEffect(() => {
    dispatch(
      MetaDataSagaActions.getFolderById({
        folderId,
      })
    );
  }, [folderId]);

  useEffect(() => {
    // dispatch(MetaDataSagaActions.fetchBrandLists());
    // dispatch(MetaDataSagaActions.fetchTagLists());
    return () => {
      // dispatch(MetaDataReducerAction.setBrandFilterSearchText(""));
      dispatch(MetaDataReducerAction.setOpenRegisterNowModal(false));
    };
  }, []);

  const fetchMoreSwipeFileItem = () => {
    dispatch(
      MetaDataSagaActions.fetchFolderAdsList({
        selectedFolderId: folderId,
        selectedTabIndex,
      })
    );
  };

  const RenderAdsComponent = useMemo(() => {
    const myItemsLength = boardAdsItems?.data?.length ?? 0;
    const hasMoreData = boardAdsItems?.total > myItemsLength;
    if (selectedFolderDetails?.boards?.length === 0) {
      return (
        <ListContainer>
          <HeaderTitle textAlign="center">
            Create your first board and Save Ads
          </HeaderTitle>
          <HeaderSubTitle textAlign="center">
            Add Multiple Boards in Folders and enjoy organizing Ads.
          </HeaderSubTitle>
        </ListContainer>
      );
    }

    if (!isLoading && myItemsLength === 0) {
      return (
        <ListContainer>
          <HeaderTitle textAlign="center">
            Your saved Ads will appear here
          </HeaderTitle>
          <HeaderSubTitle textAlign="center">
            Use our extension to save ads from platforms and grow
          </HeaderSubTitle>
        </ListContainer>
      );
    }

    return (
      <ListContainer>
        <CardListWrapper>
          {boardAdsItems?.data?.map(
            (item: SwipeFileListItems, index: number) => (
              <PublicAdCard item={item} key={index} />
            )
          )}
          {(isLoading || hasMoreData) &&
            [...Array(4)]?.map((index) => (
              <PublicAdCard item={{} as SwipeFileListItems} key={index} />
            ))}
        </CardListWrapper>
        <InfiniteScroll
            scrollableTarget={document.getElementById('content') as ReactNode}
          dataLength={myItemsLength}
          next={fetchMoreSwipeFileItem}
          hasMore={hasMoreData}
          loader={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          }
          style={{ overflow: "hidden" }}
          key={myItemsLength}
        >
          <></>
        </InfiniteScroll>
      </ListContainer>
    );
  }, [boardAdsItems?.data, boardAdsItems?.total, isLoading, selectedTabIndex]);

  const handleTabs = (event: React.SyntheticEvent, newValue: number) => {
    setSwipeFileTabIndex(newValue);
  };

  return (
    <Container>
      <HeaderSection>
        <HeaderFolderPublicRoute content={content} />
        <SearchAndFilterFolderPublicRoute
          handleFilter={fetchfilterSwipeFileItems}
          selectedBrands={selectedBrands}
          selectedPlatforms={selectedPlatforms}
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
          handleSortFilter={fetchSortingSwipeFileItems}
          selectedFilterOption={filterOption?.sortValue}
          renderBoardFilter={false}
          filterCounts={boardAdsFilterCount}
          handleClearFilter={handleClearSwipeFileFilter}
          userId={userDetails?.user?._id}
          renderTagFilter={true}
          selectedTags={selectedTags}
        />
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              display: "flex",
              paddingX: "20px",
              gap: "5px",
              alignItems: "center !important",
              justifyContent: "center",
            }}
          >
            <FolderIconDark />
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "#414042" }}
            >
              Boards:
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ m: 0 }}
          />
          {selectedFolderDetails?.boards?.length > 0 && (
            <Tabs
              value={selectedTabIndex}
              onChange={handleTabs}
              variant="scrollable"
              scrollButtons={false}
              sx={{
                ".MuiTabs-flexContainer": { gap: "5rem" },
                minHeight: "3rem",
                "& .MuiTabs-scroller": {
                  overflowX: "auto",
                  scrollbarWidth: "none",
                  "-ms-overflow-style": "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "-webkit-overflow-scrolling": "touch",
                },
              }}
            >
              {selectedFolderDetails?.boards?.map(
                (item: selectFolderBoardItem) => (
                  <Tab
                    key={item?._id}
                    label={item?.name}
                    sx={{
                      "&.Mui-selected": {
                        color: theme.colors.blue,
                        borderBottom: "1px solid",
                        fontWeight: "600",
                      },
                      color: theme.colors.gray1,
                      textTransform: "capitalize",
                      paddingBottom: "1rem",
                      minWidth: "fit-content",
                      fontSize: "1rem",
                      minHeight: "3rem",
                    }}
                  />
                )
              )}
            </Tabs>
          )}
          {selectedFolderDetails?.boards?.length === 0 && (
            <Typography
              sx={{ fontSize: "14px", color: "#414042", marginLeft: "8px" }}
            >
              No Boards Found
            </Typography>
          )}
        </Box>

        {RenderAdsComponent}
      </HeaderSection>
      {openRegisterNowModal && <RegisterNowModal />}
    </Container>
  );
};

export default PublicMyFolder;
