import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  BoardIcon,
  CreateNewIcon,
  DeleteIcon,
  EditIcon,
  EllipsisIcon,
  EllipsisIconLight,
  GridViewIcon,
  LockFolder,
  OpenFolder,
  ShareIcon,
  StarredIcon,
  VisibleIcon,
} from "../../assets/Icons";
import {
  BlurredOverlay,
  FolderListItem,
  PanelItem,
} from "../../pageComponents/auth/style";
import useConfig from "../../hooks/useConfig";
import { folderItem, selectFolderBoardItem } from "../../store/metadata/types";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFolderLoadingSelector,
  folderItemSelector,
  folderListsSelector,
  isLoadingSelector,
  selectedTabIndexSelector,
  selectFolderDetailsSelector,
  selectFolderSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import routeConstant from "../../utils/routeConstant";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { SwipeFileSagaActions } from "../../store/swipeFile/sagas";
import { useTheme } from "styled-components";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";
import { selectedSidebarBoardIdSelector } from "../../store/swipeFile/selector";
import { useEffect, useRef, useState } from "react";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { defaultFolderName } from "../../utils/constants";
import useAuth from "../../hooks/useAuth";

const SidebarFolder = () => {
  const { isAuthenticated, isPathNameFolder } = useAuth();
  const { drawerOpen } = useConfig();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const folderList = useSelector(folderListsSelector);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedFolder = useSelector(selectFolderSelector);
  const selectedFolderDetails = useSelector(selectFolderDetailsSelector);
  const selectedTabIndex = useSelector(selectedTabIndexSelector);
  const selectedSidebarBoardId = useSelector(selectedSidebarBoardIdSelector);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [selectedActionFolder, setSelectedActionFolder] =
    useState<folderItem>();

    const folderListToShow = isAuthenticated
    ? folderList?.userFolders
    : selectedFolderDetails
    ? [selectedFolderDetails]
    : [];
  const selectedFolderToShow = isAuthenticated
    ? selectedFolder
    : selectedFolderDetails;

  const [prevFolderCount, setPrevFolderCount] = useState(folderListToShow);
  const [isEllipsesOpen, setIsEllipsesOpen] = useState<string | null>(null);
  const [localSelectedFolder, setLocalSelectedFolder] =
    useState<folderItem | null>(null);

  const loading = useSelector(deleteFolderLoadingSelector);

  useEffect(() => {
    if (selectedFolderDetails) {
      setLocalSelectedFolder(selectedFolderDetails);
      setIsEllipsesOpen(selectedFolderDetails._id);
    }
  }, [selectedFolderDetails]);

  const handleAnClick = (
    event: React.MouseEvent<HTMLElement>,
    item?: folderItem,
    board?:any,
    index?:number,
  ) => {
 const newItem = Object.assign({}, item,{selectedBoard:board,selectedIndex: index });

    setAnchorEl(event.currentTarget);
    dispatch(MetaDataReducerAction.resetSkipLimitValue());
    dispatch(MetaDataReducerAction.setFolderItem(newItem));
    setSelectedActionFolder(newItem);
  };

  const selectedFolderItem = useSelector(folderItemSelector);

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(MetaDataReducerAction.setFolderItem(null));
  };

  const handleMenuItemClick = () => {
    dispatch(
      MetaDataReducerAction.setIsRemoveUserModalOpen({
        isOpen: true,
        isUser: false,
      })
    );
    setAnchorEl(null);
  };

  const handleEditFolder = () => {
    dispatch(MetaDataReducerAction.setOpenCreateFolder(true));
    setAnchorEl(null);
  };

  const handleViewFolder = () => {
      if(selectedFolderItem?.selectedBoard) {
          handleClick?.(selectedFolderItem?.selectedBoard?._id,selectedFolderItem,selectedFolderItem?.selectedIndex);
      }else{
          dispatch(MetaDataReducerAction.resetSwipefileState());
          dispatch(
              MetaDataReducerAction.setSelectedFolderId(selectedActionFolder?._id)
          );
          dispatch(MetaDataReducerAction.setSelectedFolder(selectedActionFolder));
          dispatch(MetaDataReducerAction.setSelectedTabIndex(0));

          const {_id} = selectedFolderItem;
          navigate(`${routeConstant.myFolders}/${_id}`, {
              state: {
                  type: "sidebarFolder",
              },
          });
      }
      handleClose();

  };

  const handleClick = (
    boardId: string,
    folderItem: folderItem,
    index: number
  ) => {
    dispatch(MetaDataReducerAction.setSelectedFolder(folderItem));
    dispatch(SwipeFileReducerAction.setSelectedSidebarBoardId(boardId));
    dispatch(MetaDataReducerAction.setSelectedTabIndex(index));
    dispatch(MetaDataReducerAction.resetSwipefileState());

    dispatch(
      MetaDataSagaActions.fetchFolderListByBoardId({
        selectedFolderId: folderItem._id,
        selectedTabIndex: index,
        boardId: boardId,
      })
    );

    navigate(`${routeConstant.myFolders}/${folderItem._id}`, {
      state: {
        type: "sidebarFolder",
      },
    });
  };

  const closeAnchorElAfterDelete = () => {
    setAnchorEl(null);
  };


  const handleDelete = () => {

      if(selectedFolderItem?.selectedBoard){
              dispatch(
                  MetaDataSagaActions.deleteBoard({
                      boardId: selectedFolderItem?.selectedBoard?._id,
                      folderId: selectedFolderItem?._id,
                      resetState: closeAnchorElAfterDelete,
                  })
              );
      }else {
          dispatch(
            MetaDataSagaActions.deleteFolder({
              closeAnchorElAfterDelete: closeAnchorElAfterDelete,
            })
          );
      }

  };

  const constants = {
    myFolders: "My Folders",
  };
  const selectedFolderId = location.pathname.split("/")[2];
  const selectedFolderName = location.pathname.split("/")[1];
  const isSelectedInCurrentFolder =
    selectedFolderName === Object.keys(constants)[0];

  useEffect(() => {
    const currentFolderCount = folderList?.userFolders?.length || 0;

    // Only scroll to bottom if a new folder is added after the initial load
    if (
      currentFolderCount > prevFolderCount &&
      prevFolderCount !== 0 &&
      scrollRef.current
    ) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }

    // Update the previous folder count
    setPrevFolderCount(currentFolderCount);
  }, [folderList?.userFolders]);

  const handleBlurSectionClick = () => {
    if (!isAuthenticated) {
      dispatch(MetaDataReducerAction.setOpenRegisterNowModal(true));
    }
  };

    const scrollToCenter = (folderId:string) => {
        setTimeout(() =>{
            const element = document.getElementById(folderId);

            if (scrollRef.current && element) {
                const parentScrollEndAt = scrollRef.current.scrollTop+scrollRef.current.clientHeight;
                const elementTopWidthParent = element.offsetTop - scrollRef.current.offsetTop;
                if(elementTopWidthParent < scrollRef.current.scrollTop){
                    scrollRef.current.scrollTo({
                        top:elementTopWidthParent,
                        behavior:"smooth"
                    })
                    return
                }
                if(element.clientHeight + elementTopWidthParent <= parentScrollEndAt){
                }else{
                    if(element.clientHeight >= scrollRef.current.clientHeight){
                        scrollRef.current.scrollTo({
                            top:elementTopWidthParent,
                            behavior:"smooth"
                        })
                    }else {


                        const scrollByPoints = (element.clientHeight + elementTopWidthParent) - parentScrollEndAt;
                        console.log({scrollByPoints})
                        scrollRef.current.scrollBy({
                            top: scrollByPoints,
                            behavior: "smooth"
                        })
                    }
                }
            }
        },200)
    };
  return (
    <>
      <Divider sx={{ borderColor: "#2f4757" }} />
      <Box sx={{ position: "relative" }}>
        {!isAuthenticated && (
          <BlurredOverlay onClick={handleBlurSectionClick} />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "1.5rem 1.5rem 0.5rem 1.8rem",
          }}
        >
          <span>{constants?.myFolders}</span>
          <div
            onClick={() => {
              dispatch(MetaDataReducerAction.setOpenCreateFolder(true));
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <CreateNewIcon fill="white" />
          </div>
        </div>
      </Box>

      <Box
        ref={scrollRef}
        sx={{
          marginLeft: "1rem",
          marginBottom: "1rem",
          height: "100%",
          maxHeight: {
            xs: "47vh",
            sm: "39vh",
            md: "32vh",
            lg: "27vh",
            xl: "31vh",
          },
          overflowY: "auto",
          overflowX: "hidden",
          "::-webkit-scrollbar": {
            width: "4px",
          },
        }}
      >
        {isAuthenticated && (
          <PanelItem
            drawerOpen={drawerOpen}
            onClick={() => {
              dispatch(MetaDataReducerAction.setSelectedFolder(null));
              setLocalSelectedFolder(null);
            }}
            to={`${routeConstant.starred}`}
            style={{
              background:
                location.pathname === routeConstant.starred
                  ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                  : "transparent",
            }}
          >
            <StarredIcon />
            {drawerOpen && <Typography marginLeft={1}>Starred </Typography>}
          </PanelItem>
        )}

        <Box
          sx={{
            marginTop: "-0.5rem",
          }}
        >
          {folderListToShow?.length > 0 &&
            folderListToShow?.map((folderItem: folderItem) => (
              <div id={folderItem?._id} key={folderItem._id}>
                <FolderListItem
                  key={folderItem._id}
                  style={{
                    borderRadius: "4px",
                    background:
                      isEllipsesOpen === folderItem?._id
                        ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                        : "transparent",
                  }}
                >
                  {folderItem?.isPrivate ? <LockFolder /> : <OpenFolder />}
                  {drawerOpen && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          if (isEllipsesOpen !== folderItem?._id) {
                            setIsEllipsesOpen(folderItem?._id);
                              scrollToCenter(folderItem?._id)
                            // setLocalSelectedFolder(folderItem);
                            // dispatch(
                            //   MetaDataReducerAction.setSelectedFolder(item)
                            // );
                            // dispatch(
                            //   MetaDataReducerAction.setSelectedTabIndex(0)
                            // );
                            // dispatch(
                            //   MetaDataReducerAction.resetSwipefileState()
                            // );
                            // dispatch(
                            //   MetaDataSagaActions.fetchFolderListByBoardId({
                            //     selectedFolderId: item?._id,
                            //     selectedTabIndex: 0,
                            //     boardId: item?.boards[0]?._id,
                            //   })
                            // );
                          } else {
                            setIsEllipsesOpen(null);
                            setLocalSelectedFolder(null);
                          }
                        }}
                        style={{
                          marginLeft: 10,
                          cursor:
                            selectedFolderId !== folderItem?._id
                              ? "pointer"
                              : "default",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        {folderItem.name}
                      </div>

                      {isAuthenticated &&
                        folderItem.name !== defaultFolderName && (
                          <div
                            onClick={(event) =>
                              handleAnClick(event, folderItem)
                            }
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {selectedFolderToShow?._id === folderItem?._id ? (
                              <EllipsisIconLight />
                            ) : (
                              <EllipsisIcon />
                            )}
                          </div>
                        )}
                    </div>
                  )}
                </FolderListItem>

                <Collapse
                  in={isEllipsesOpen === folderItem?._id}
                  timeout="auto"
                  unmountOnExit
                >
                  <span
                    style={{
                      marginLeft: "2.5rem",
                      marginBottom: "0.625rem",
                      display: "block",
                      cursor: "pointer",
                    }}
                  >
                    {folderItem?.boards &&
                    folderItem?.boards?.length > 0
                      ? "Boards"
                      : "No Boards"}
                  </span>
                  {folderItem?.boards?.map(
                    (boardItem: selectFolderBoardItem, index: number) => (
                        <List key={boardItem?._id} style={{display:'flex'}} component="div" disablePadding>
                          <ListItemButton
                              sx={{
                                pl: 4,
                                gap: "0.188rem",
                                paddingLeft: "0.625rem",
                                borderLeft: "1px solid #ffff",
                                marginLeft: "2.625rem",
                                paddingTop: "0.625rem",
                                cursor: "pointer",
                                fontWeight: "bolder",
                                color:
                                    folderItem?._id === selectedFolderId &&
                                    selectedTabIndex === index
                                        ? theme.colors.lightBlue
                                        : "white",
                              }}
                              onClick={() =>
                                  handleClick(boardItem?._id, folderItem, index)
                              }
                          >
                            <ListItemIcon
                                sx={{
                                  minWidth: "1.5rem",
                                  marginLeft: "0.75rem",
                                }}
                            >
                              <BoardIcon fill="white"/>
                            </ListItemIcon>
                            <ListItemText
                                primary={boardItem?.name}
                                sx={{
                                  cursor: "pointer",
                                }}
                            />
                          </ListItemButton>

                          <div
                              onClick={(event) =>
                                  handleAnClick(event, folderItem,boardItem,index)
                              }
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                  paddingRight:'10px',
                              }}
                          >
                                <EllipsisIcon/>
                          </div>
                        </List>
                    )
                  )}
                </Collapse>
              </div>
            ))}
        </Box>
      </Box>
      <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            ".MuiMenu-paper": {
              borderRadius: "0.625rem",
            },
            ".MuiList-root": {
              padding: "0",
              display: "flex",
              flexDirection: "column",
            },
          }}
      >
        <MenuItem
            onClick={handleViewFolder}
            style={{
              gap: "0.75rem",
              padding: "14px 15px",
              borderBottom: "1px solid #E3E6EA",
            }}
        >
          <VisibleIcon/>
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color={theme.colors.black2}
            style={{
              cursor: "pointer",
            }}
          >
            View
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={handleEditFolder}
          style={{
            gap: "0.75rem",
            padding: "14px 15px",
            borderBottom: "1px solid #E3E6EA",
          }}
        >
          <EditIcon />
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color={theme.colors.black2}
            style={{
              cursor: "pointer",
            }}
          >
            Edit
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={handleDelete}
          style={{
            gap: "0.75rem",
            padding: "14px 15px",
            borderBottom: "1px solid #E3E6EA",
          }}
        >
          {loading ? (
            <CircularProgress size={20} sx={{ color: theme.colors.black2 }} />
          ) : (
            <DeleteIcon />
          )}
          <Typography
            fontSize="1rem"
            fontWeight="500"
            style={{
              cursor: "pointer",
            }}
          >
            Delete
          </Typography>
        </MenuItem>

          {!selectedFolderItem?.selectedBoard ?
        <MenuItem
          onClick={handleMenuItemClick}
          style={{
            gap: "0.75rem",
            padding: "14px 15px",
          }}
        >
          <ShareIcon />
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color={theme.colors.black2}
            style={{
              cursor: "pointer",
            }}
          >
            Share
          </Typography>
        </MenuItem> : ''}
      </Menu>
    </>
  );
};

export default SidebarFolder;
