import { Middleware, configureStore } from "@reduxjs/toolkit";
import { createInjectorsEnhancer } from "redux-injectors";
import createSagaMiddleware from "@redux-saga/core";
import { createRootReducer } from "./rootReducer";
import { rootSaga } from "./sagas";
// import { createLogger } from "redux-logger";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware] as Middleware[];
const { run: runSaga } = sagaMiddleware;
// const logger = createLogger({
//   level: "warn",
// });

// middlewares.push(logger as any);

const enhancers: any = [
  createInjectorsEnhancer({
    runSaga,
    createReducer: createRootReducer as any,
  }),
];

const store = configureStore({
  reducer: createRootReducer(),
  middleware: [...middlewares],
  enhancers,
});
sagaMiddleware.run(rootSaga);

export { store };
