import { brandItem, listType, tagItem } from "../metadata/types";

export type DiscoveryStateTypes = {
  discoveryItems: discoveryItemListsType;
  communityItems: discoveryItemListsType;
  viewType: ViewTypeEnum;
  isLoading: boolean;
  filterOptions: FilterOptions;
  discoveryTabIndex: number;
  discoveryTopBrandLists: discoveryTopBrandListsType;
  discoveryBrandsParams: discoveryBrandsParamsType;
  search: string;
  discoveryFavouriteBrandFilterEnabled: boolean;
  discoveryBrandsLoading: boolean;
};

export type DiscoveryItem = {
  _id: string;
  description?: string;
  libraryId: string;
  startedRunningDate: string;
  platforms: [];
  imageUrls: string[];
  videoUrls: string[];
  adTargetSourceURL: string;
  pageId?: string;
  userId?:string;
  ArchiveShow?:boolean;
  isArchived?:boolean;
  createdAt: string;
  updatedAt: string;
  page: PageType;
  isActive: boolean;
  board: boardType[];
  Tags: tagItem[];
  ratings: { _id: string; rating: number }[];
  transcript: transcriptObject;
};

export type PageType = {
  _id: string;
  name: string;
  logo: string;
  url: string;
  createdAt: string;
  updatedAt: string;
};

export enum ViewTypeEnum {
  List,
  Card,
}

export type FilterOptions = {
  selectedPlatformIds?: string[];
  selectedPageIds?: string[];
  selectedBoardIds?: string[];
  limit: number;
  skip: number;
  sortValue: string;
  sort: Record<string, any>;
  selectedTagIds?: string[];
};

export type boardType = {
  _id: string;
};

export type discoveryTopBrandListsType = {
  top10: brandItem[];
  data: brandItem[];
  total: number;
};

export type discoveryBrandsParamsType = {
  skip: number;
  limit: number;
  favourite: boolean;
};

export type discoveryItemListsType = {
  data: DiscoveryItem[];
  total: number;
};

export interface SetFilterOptionsPayload<T extends keyof FilterOptions> {
  selectAll: boolean;
  checked: boolean;
  id: string;
  list: listType[];
  stateName: T;
}

export type transcriptObject = {
  results: {
    channels: [
      {
        alternatives: alternativesObject[];
      }
    ];
    utterances: utterancesObject[];
  };
};

export type utterancesObject = {
  start: number;
  end: number;
  confidence: number;
  transcript: string;
  id: string;
};

export type alternativesObject = {
  transcript: string;
};
