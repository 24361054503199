import { Stack, Typography } from "@mui/material";
import ChromeImage from "../../assets/images/chrome-image.png";
import { CardContainer, DashboardCardButton } from "./style";
import { useTheme } from "styled-components";
import { chromeExtensionUrl } from "../../utils/constants";

const DownloadExtensionCard = () => {
  const theme = useTheme();
  
  const handleButtonClick = () => {
    window.open(chromeExtensionUrl, "_blank");
  };
  return (
    <CardContainer
      sx={{
        width: "50%",
        alignItems: "center",
        gap: "0.938rem",
        display: "flex",
      }}
    >
      <img
        src={ChromeImage}
        alt=""
        style={{ width: "3.75rem", height: "3.75rem" }}
      />
      <Stack>
        <Typography
          fontSize="1.375rem"
          fontWeight="600"
          color={theme.colors.black2}
        >
          Download the Chrome Extension
        </Typography>
        <Typography fontSize="1rem" fontWeight="400" color={theme.colors.gray1}>
          Save ads from Facebook, Instagram, and LinkedIn
        </Typography>
      </Stack>
      <DashboardCardButton
        sx={{
          marginLeft: "auto",
        }}
        onClick={handleButtonClick}
      >
        Add to Chrome
      </DashboardCardButton>
    </CardContainer>
  );
};

export default DownloadExtensionCard;
