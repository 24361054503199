import {
  SearchFilterSection,
  OptionSection,
  OptionButtonText,
  OptionButton,
  AppliedFilterText,
  SearchWrapper,
  SearchInput,
  SearchIconWrapper,
} from "../../pageComponents/style";
import { ArrowIcon, AtoZIcon, FilterIcon } from "../../assets/Icons";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useRef, useState } from "react";
import { Box, Divider } from "@mui/material";
import MenuPopover from "../popover";
import { useTheme } from "styled-components";
import SortPopover from "../sortPopover";
import { useDispatch } from "react-redux";
import useScrollingUp from "../../hooks/useScrollingUp";
import useConfig from "../../hooks/useConfig";
import { MetaDataSagaActions } from "../../store/metadata/sagas";

const SearchAndFilterFolderPublicRoute = ({
  handleFilter,
  selectedBrands,
  selectedPlatforms,
  handleSortFilter,
  selectedFilterOption,
  renderBoardFilter,
  selectedBoards,
  filterCounts,
  handleClearFilter,
  userId,
  renderTagFilter,
  selectedTags,
  searchValue,
  handleSearchChange,
  hanldeSearchButton,
}: {
  handleFilter: Function;
  selectedBrands: string[];
  selectedPlatforms: string[];
  handleSortFilter: Function;
  selectedFilterOption: string;
  renderBoardFilter?: boolean;
  selectedBoards?: string[];
  filterCounts: number;
  handleClearFilter: () => void;
  userId?: string;
  renderTagFilter?: boolean;
  selectedTags?: string[];
  searchValue?: string;
  handleSearchChange?: Function;
  hanldeSearchButton?: Function;
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [sortAnchorEl, setSortAnchorEl] = useState<Element | null>(null);
  const dispatch = useDispatch();
  const { scrollRef, isSticky } = useScrollingUp();
  const { drawerOpen } = useConfig();
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isSticky) {
      anchorEl && setAnchorEl(scrollRef.current);
      sortAnchorEl && setSortAnchorEl(scrollRef.current);
    }
  }, [isSticky]);

  const handlePopover = () => {
    setAnchorEl(isSticky ? popupRef.current : scrollRef.current);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const clearAllFilters = () => {
    handleClearFilter();
    dispatch(MetaDataSagaActions.clearAllFilter({ userId }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleSearchChange && handleSearchChange(value);
  };

  const handleSearchClick = () => {
    hanldeSearchButton && hanldeSearchButton();
  };

  const renderContent = () => {
    return (
      <>
        <SearchWrapper variant="outlined">
          <SearchInput
            placeholder="AI Search..."
            value={searchValue ?? ""}
            onChange={handleChange}
            sx={{
              input: {
                "&::placeholder": {
                  color: theme.colors.gray1,
                  fontWeight: 500,
                  opacity: 1,
                },
              },
            }}
            endAdornment={
              <SearchIconWrapper
                position="end"
                sx={{
                  cursor: "pointer",
                }}
                onClick={handleSearchClick}
              >
                <SearchIcon />
              </SearchIconWrapper>
            }
          />
        </SearchWrapper>
        <OptionSection>
          <Box>
            <OptionButton>
              <Box
                onClick={handlePopover}
                display={"flex"}
                gap={"0.625rem"}
                alignItems={"center"}
              >
                <FilterIcon />
                <OptionButtonText>Filters</OptionButtonText>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem />
              <AppliedFilterText
                color={filterCounts ? theme.colors.blue : theme.colors.gray1}
                onClick={() => filterCounts && clearAllFilters()}
              >
                {filterCounts ? `Clear ${filterCounts}` : "No filters applied"}
              </AppliedFilterText>
            </OptionButton>
          </Box>
          <OptionButton
            onClick={(event) =>
              setSortAnchorEl(isSticky ? popupRef.current : scrollRef.current)
            }
          >
            <AtoZIcon />
            <OptionButtonText>Sort by</OptionButtonText>
            <ArrowIcon />
          </OptionButton>
        </OptionSection>
      </>
    );
  };

  return (
    <>
      {isSticky && (
        <SearchFilterSection
          isSticky={isSticky}
          drawerOpen={drawerOpen}
          ref={popupRef}
        >
          {renderContent()}
        </SearchFilterSection>
      )}
      <SearchFilterSection ref={scrollRef}>
        {renderContent()}
      </SearchFilterSection>
      <MenuPopover
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        handleFilter={handleFilter}
        selectedBrands={selectedBrands}
        selectedPlatforms={selectedPlatforms}
        renderBoardFilter={renderBoardFilter}
        selectedBoards={selectedBoards}
        userId={userId}
        renderTagFilter={renderTagFilter}
        selectedTags={selectedTags}
      />
      <SortPopover
        anchorEl={sortAnchorEl}
        handlePopoverClose={() => setSortAnchorEl(null)}
        handleSortFilter={handleSortFilter}
        selectedFilterOption={selectedFilterOption}
      />
    </>
  );
};

export default SearchAndFilterFolderPublicRoute;
